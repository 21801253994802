import React, { useEffect, useState } from "react";

const ViewGiftModal = ({ registryItems, itemId }) => {
  const [selectedRegistryItem, setSelectedRegistryItem] = useState(null);

  useEffect(() => {
    if (itemId && registryItems) {
      const selectedRegistryItem = registryItems.find(item => item.id === itemId);
      if (selectedRegistryItem) {
        setSelectedRegistryItem(selectedRegistryItem);
      }
    }
  }, [itemId, registryItems]);

  const handleViewGift = () => {
    if (selectedRegistryItem && selectedRegistryItem.item_url) {
      window.open(selectedRegistryItem.item_url, '_blank');
    }
  };  

  return (
    <div className="modal fade" id="ViewGift" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog common-popup edit-gifts-popup">
        <div className="modal-content">
          <div className="modal-header">
            <p>GIFT PREVIEW</p>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body login-box row">
            <div className="col-lg-6 col-sm-6">
              <div className="product-image">
                <div className="product-imgs">
                  <div className="img-display">
                    <div className="img-showcase">
                      {selectedRegistryItem && selectedRegistryItem.image ? (
                        <img
                          src={selectedRegistryItem.image}
                          alt=""
                          className="d-block mx-auto"
                          style={{ height: "300px", objectFit: "contain" }}
                        />
                      ) : (
                        <img
                          src="https://www.mobismea.com/upload/iblock/2a0/2f5hleoupzrnz9o3b8elnbv82hxfh4ld/No%20Product%20Image%20Available.png"
                          alt=""
                          className="d-block mx-auto"
                          style={{ height: "300px", objectFit: "contain" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="product-form view-gift-weight">
              <p className="change-color">{selectedRegistryItem?.platform_name?.toUpperCase()}</p>
               <hr className="mt-1" />
                <div className="user-box clamp-three-lines">
                    <p className="view-gift-name">{selectedRegistryItem?.item_name}</p>
                </div>
                <div className="product-price view-price mb-3">
                    <h3>₹ {selectedRegistryItem?.price}</h3>
                </div>
                <div className="row">
                  <div className="desired-quantity mb-3">
                    <p>DESIRED: {selectedRegistryItem?.required_qty}</p>
                  </div>
                  <div className="purchased-quantity mb-3">
                    <p>PURCHASED: {selectedRegistryItem?.purchased_qty}</p>
                  </div>
                  {selectedRegistryItem?.item_details?.color && (
                    <div className="color">
                      <p>Colour: {selectedRegistryItem?.item_details?.color}</p>
                    </div>
                  )}
                  {selectedRegistryItem?.item_details?.size && (
                    <div className="size">
                      <p>Size: {selectedRegistryItem?.item_details?.size}</p>
                    </div>
                  )}
                  {selectedRegistryItem?.item_details?.notes && (
                    <div className="notes">
                      <p>Notes: {selectedRegistryItem?.item_details?.notes}</p>
                    </div>
                  )}
                  <div className="mt-4">
                    <button
                          type="button"
                          className="form-btn view-store-btn mb-3"
                          onClick={handleViewGift}
                        >
                          VIEW AT STORE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewGiftModal;