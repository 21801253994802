import React, { useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import Navbar from "../../components/common/Navbar";
import { brandImages, categoryImages } from "../../utils/imagesUtils";
// import { ageImages, brandImages, categoryImages, wishlistsImages } from "../../utils/imagesUtils";

const ShopPage = () => {
  const sliderSettings = {
    infinite: true,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      }
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          <Navbar />
        </header>
        <main className="main">
          <article>
            <section>
              <div className="shop-container">
                <div className="row">
                  <div className="col-lg-12">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                      <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button> */}
                      </div>
                      <div className="carousel-inner">
                        {/* <div className="carousel-item active">
                          <img src="/assets/images/shop/cover/carters.png" className="d-block w-100" alt="Slide 1" />
                        </div> */}
                        <div className="carousel-item active">
                          <img src="/assets/images/shop/cover/r-for-rabbit.jpg" className="d-block w-100" alt="Slide 1" />
                        </div>
                        <div className="carousel-item">
                          <img src="/assets/images/shop/cover/amazon.jpeg" className="d-block w-100" alt="Slide 2" />
                        </div>
                        <div className="carousel-item">
                          <img src="/assets/images/shop/cover/slide-4.png" className="d-block w-100" alt="Slide 3" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="py-5">
              <div className="shop-container adjust-shop-container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="browse-heading">
                        <h2>Browse Recommended Products</h2>
                    </div>
                    {/* <div className="sliding-container">
                      <div className="cat-title-wrapp">
                        <h4>By Age</h4>
                      </div>
                      <div className="w-100 d-flex justify-content-end m-0 p-0">
                        <Link to="/categories?type=age" className="show-all-link">
                           Show all
                        </Link>
                      </div>
                      <div>
                        <Slider {...sliderSettings}>
                            {ageImages.map((image, index) => (
                            <div key={index} className="carousel-items">
                                <Link to={image.link}>
                                <img src={image.src} className="d-block w-100" alt={image.alt} />
                                <p className="image-caption">{image.alt}</p>
                                </Link>
                            </div>
                            ))}
                        </Slider>
                      </div>
                    </div> */}
                    <div className="sliding-container">
                      <div className="cat-title-wrapp adjust-cat-lg">
                        <h4>By Brand / Retailer</h4>
                      </div>
                      <div className="w-100 d-flex justify-content-end m-0 p-0">
                        <Link to="/categories?type=brand" className="show-all-link">
                           Show all
                        </Link>
                      </div>
                      <Slider {...sliderSettings}>
                        {brandImages.map((image, index) => (
                          <div key={index} className="carousel-items items-brand">
                            <Link to={image.link}>
                                <img src={image.src} className="d-block w-100" alt={image.alt} />
                            </Link>
                          </div>
                        ))}
                      </Slider>
                    </div>
                    <div className="sliding-container">
                      <div className="cat-title-wrapp adjust-cat">
                        <h4>By Category</h4>
                      </div>
                      <div className="w-100 d-flex justify-content-end m-0 p-0">
                        <Link to="/categories?type=category" className="show-all-link">
                           Show all
                        </Link>
                      </div>
                      <Slider {...sliderSettings}>
                        {categoryImages.map((image, index) => (
                          <div key={index} className="carousel-items">
                            <Link to={image.link}>
                              <img src={image.src} className="d-block w-100" alt={image.alt} />
                              <p className="image-caption">{image.alt}</p>
                            </Link>
                          </div>
                        ))}
                      </Slider>
                    </div>
                    {/* <div className="sliding-container">
                      <div className="cat-title-wrapp adjust-cat-lg">
                        <h4>Example Wishlists</h4>
                      </div>
                      <div className="w-100 d-flex justify-content-end m-0 p-0">
                        <Link to="/categories?type=wishlists" className="show-all-link">
                           Show all
                        </Link>
                      </div>
                      <Slider {...sliderSettings}>
                        {wishlistsImages.map((image, index) => (
                          <div key={index} className="carousel-items">
                            <Link to={image.link}>
                              <img src={image.src} className="d-block w-100" alt={image.alt} />
                              <p className="image-caption">{image.alt}</p>
                            </Link>
                          </div>
                        ))}
                      </Slider>
                    </div> */}
                  </div>
                </div>
              </div>
            </section>
          </article>
        </main>
        <Footer />
      </div>
    </div>
  );
};

const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow next-arrow`}
        onClick={onClick}
      >
      </div>
    );
  };
  
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow prev-arrow`}
        onClick={onClick}
      >
      </div>
    );
  };

export default ShopPage;