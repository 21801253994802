import React from "react";
import { Link } from 'react-router-dom';

const CreateWishlistSection = () => {
  return (
    <main className="main">
      <article>
        <section className="py-5 store light-bg" data-aos="fade-up">
          <div className="icons-bg"></div>

          <div className="container">
            <div className="row">
              <div
                className="col-lg-12"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="store-box unwanted-gifts text-center">
                  <h1 className="my-5 heading-lmed">Ready to dive into Baby gift bliss, with No more duplicates or unwanted gifts!</h1>
                  <p className="my-5">
                    Our smart registry ensures you’ll only get the gifts your baby needs. Once a gift is purchased, your friends and family
                    can mark it as gifted and others can gift confidently, having the pleasure of knowing their gift will be put to good use
                  </p>
                </div>
              </div>

              <div
                className="col-lg-12 col-md-12 text-center"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="button">
                  <Link to="/signup" className="button-active">
                    Create Your Wishlist
                  </Link>
                </div>

                <div className="spacing-small"></div>
              </div>
            </div>
          </div>
        </section>
      </article>
    </main>
  );
};

export default CreateWishlistSection;
