/* global $ */
import React from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import { showToast } from "../../../utils/toastUtils";
import { getRegistryCredentials } from "../../../utils/registryUtils";

const UnavailableModal = ({ itemId, itemUrl, fetchRegistryItems, fetchRegistryDetails }) => {
  const { accessToken } = getRegistryCredentials();

  const handleMarkItemAvailable = async () => {
    try {
      await api.markItemAvailable(accessToken, itemId);
      $("#unavailable-modal").modal("hide");
      showToast('Your gift has been restored');
      fetchRegistryDetails();
      fetchRegistryItems();
    } catch (error) {
      console.error("Error marking item as available:", error);
    }
  };

  const handleOpenDeleteModal = () => {
    $("#unavailable-modal").modal("hide");
    $("#delete-item-modal").modal("show");
  };

  const handleConfirmDelete = async () => {
    try {
      await api.deleteRegistryItem(accessToken, itemId);
      showToast('Your gift has been deleted', "success");
      $("#delete-item-modal").modal("hide");
      fetchRegistryDetails();
      fetchRegistryItems();
    } catch (error) {
      console.error("Error deleting registry item:", error.message);
      showToast('Failed to delete gift', "error");
    }
  };

  const handleCancelDelete = () => {
    $("#delete-item-modal").modal("hide");
  };

  return (
    <>
            <div className="modal fade" id="unavailable-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog common-popup purchaser-details-popup">
        <div className="modal-content">
          <div className="modal-header">
            <p>UNAVAILABLE</p>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body row mt-3">
            <div className="unavailable-box-info">
              <div className="unavailable-info text-center">
                <p>This item may no longer be available</p>
              </div>

              <hr className="separate-line mt-4 mb-4" />
              <div className="unavailable-container">
                <p className="unavailable-heading mb-3">WOULD YOU LIKE TO:</p>
                <ul className="custom-bullets">
                  <li className="sub-info">
                    <Link to={itemUrl} target="_blank" rel="noopener noreferrer">Check availability in store</Link>
                  </li>
                  <li className="sub-info">
                    <Link to="#" onClick={handleMarkItemAvailable}>Make this item available</Link>
                  </li>
                  <li className="sub-info">
                    <Link to="#" onClick={handleOpenDeleteModal}>Delete this item from my gift list</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="modal fade" id="delete-item-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-body row">
                <div className="col-lg-12">
                <div className="confirmation-modal text-center mt-4">
                    <h5 className="mb-4">Delete a Gift</h5>
                    <p> Are you sure you want to delete this gift?</p>
                </div>
                <div className="user-detials-btn text-center mt-4 mb-4">
                    <button type="button" className="common-modal-btn common-btn" onClick={handleCancelDelete}>NO</button>
                    <button type="button" className="common-modal-btn common-btn active-btn" onClick={handleConfirmDelete}>YES</button>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    </>
  );
};

export default UnavailableModal;
