/* global $ */
import React from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import { showToast } from "../../../utils/toastUtils";
import { getRegistryCredentials } from '../../../utils/registryUtils';

const ActivateAccountModal = () => {
  const { userEmail } = getRegistryCredentials();

  const handleEmailVerification = async () => {
    try {
      $("#activate-account").modal("hide");
      await api.sendVerificationEmail(userEmail);
      showToast('Activation email has been sent successfully!', "success");
    } catch (error) {
      console.error('Error resending verification email:', error);
      showToast('Failed to resend activation email. Please try again.', "error");
    }
  };

  return (
    <div className="modal fade" id="activate-account" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog common-popup buy-mulitple-popup">
        <div className="modal-content">
          <div className="modal-header">
            <p>ACTIVATE YOUR ACCOUNT</p>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body email-modal-body">
            <div className="text-center">
              <p className="mb-4">To start using your registry, please activate your account through the activation email we sent you at
              <span className="user-email"> {userEmail}.</span>
              </p>
              <p>If you did not receive the activation email please <Link className="change-color" onClick={handleEmailVerification}>click here</Link> to resend it.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivateAccountModal;