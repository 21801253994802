import React from "react";
import { Navigate } from "react-router-dom";
import { getRegistryCredentials } from '../../utils/registryUtils';

const ProtectedRoute = ({ element }) => {
    const { accessToken } = getRegistryCredentials();
    const isValidAccessToken = accessToken && accessToken.match(/^\d+\|.+$/);
    const isAuthenticated = isValidAccessToken;
    return isAuthenticated ? element : <Navigate to="/" />;
};

export default ProtectedRoute;
