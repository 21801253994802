/* global $ */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getRegistryCredentials } from "../../../utils/registryUtils";
import VerificationModal from "./VerificationModal";
import api from "../../../services/api";

const ShippingInfoModal = () => {
  const { publicKey } = useParams();
  const [shippingData, setShippingData] = useState(null);
  const { accessToken } = getRegistryCredentials();

  const fetchShippingData = async () => {
    const { jsonWebToken } = getRegistryCredentials();
    try {
      if (accessToken) {
        const data = await api.getAddressToVisitor(accessToken, publicKey);
        setShippingData(data.shipping_info);
      } else {
        if (jsonWebToken) {
          const data = await api.showAddressToVisitor(jsonWebToken, publicKey);
          setShippingData(data.shipping_info);
        } else {
          console.error("Visitor is not verified.");
        }
      }
    } catch (error) {
      console.error("Error fetching shipping data:", error.message);
    }
  };

  useEffect(() => {
    fetchShippingData();
    // eslint-disable-next-line
  }, [accessToken, publicKey]);

  const handleVisitorVerification = () => {
    $("#visitor-verification").modal("show");
    $("#shipping-info").modal("hide");
  }

  return (
    <>
      <div className="modal fade" id="shipping-info" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog common-popup">
          <div className="modal-content">
            <div className="modal-header">
              <p>SHIPPING INFO</p>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body row mt-3">
              <div className="user-address-shipping-info">
                {shippingData ? (
                  <div className="user-address-shipping-info">
                    <p className="user-address-details">
                      {shippingData.first_name}&nbsp;
                      {shippingData.last_name}
                      <hr className="separate-line mt-1 mb-1" />
                    </p>
                    <p className="visitor-shipping-details">
                      {shippingData.address_1}
                      {shippingData.address_2 && `, ${shippingData.address_2}`}
                      <br />
                      {shippingData.city},&nbsp;
                      {shippingData.state},&nbsp;
                      {shippingData.zip_code}
                      <br />
                      {shippingData.country}
                    </p>
                    <br />
                    {shippingData.instructions && (
                      <>
                        <p className="user-address-detail">Instructions:</p>
                        <hr className="separate-line mt-1 mb-1" />
                        <div>
                          <p className="visitor-shipping-details fs-6">{shippingData.instructions}</p>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="user-verify-msg mt-4 mb-5">
                    <p>Please verify yourself to view the shipping address.</p>
                    <p><Link className="change-new-color" onClick={handleVisitorVerification}>CLICK HERE</Link> to verify now.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <VerificationModal 
        fetchShippingData={fetchShippingData}
      />
    </>
  );
};

export default ShippingInfoModal;