import React, { useState, useEffect } from "react";
import LoginModal from "../auth/LoginModal";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from '../../utils/toastUtils';
import { useMediaQuery } from '@mui/material';
import { InfoIcon, HtmlTooltip } from '../../utils/tooltipUtils';
import { getRegistryCredentials } from '../../utils/registryUtils';
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { initializeGoogleAuth } from "../../utils/googleAuthUtils";
import { SignInWithApple } from "@capacitor-community/apple-sign-in";
import api from "../../services/api";
import Cookies from 'js-cookie';

const Signup = () => {
  const navigate = useNavigate();
  const isPortrait = useMediaQuery('(max-width:575px)');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { fromMobileApp, fromiOSDevice } = getRegistryCredentials();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    termsAccepted: false,
  });

  const [validation, setValidation] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    termsAccepted: "",
  });

  useEffect(() => {
    initializeGoogleAuth();
    window.scrollTo(0, 0);
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRequestPassword = () => {
    const forgotPasswordPath = "/forgot-password";
  
    if (fromMobileApp) {
      navigate(forgotPasswordPath);
    } else {
      window.open(forgotPasswordPath, "_blank");
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    setValidation((prevValidation) => ({
      ...prevValidation,
      [name]: "",
    }));
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    let isValid = true;

    if (!navigator.onLine) {
        showToast(<><i className="la la-warning i-icon"></i>&nbsp;You are offline!</>);
        return;
    }

    if (!formData.firstName.trim()) {
        setValidation((prevValidation) => ({
            ...prevValidation,
            firstName: "First name is required.",
        }));
        isValid = false;
    }

    if (!formData.lastName.trim()) {
        setValidation((prevValidation) => ({
            ...prevValidation,
            lastName: "Last name is required.",
        }));
        isValid = false;
    }

    if (!formData.email.trim()) {
        setValidation((prevValidation) => ({
            ...prevValidation,
            email: "Email Address is required. Please enter an email address.",
        }));
        isValid = false;
    } else if (!validateEmail(formData.email)) {
        setValidation((prevValidation) => ({
            ...prevValidation,
            email: "Please enter a valid email address format. Ex. name@email.com",
        }));
        isValid = false;
    }

    if (!formData.password.trim() || formData.password.length < 8) {
        setValidation((prevValidation) => ({
            ...prevValidation,
            password: "Password is required. Please enter a password of at least 8 characters.",
        }));
        isValid = false;
    }

    if (!formData.termsAccepted) {
        setValidation((prevValidation) => ({
            ...prevValidation,
            termsAccepted: "You must accept the terms and conditions to continue.",
        }));
        isValid = false;
    }

    if (isValid) {
        try {
            setLoading(true);
            const userData = {
                first_name: formData.firstName,
                last_name: formData.lastName,
                email: formData.email,
                password: formData.password,
            };
            const data = await api.register(userData);

            const userInfo = {
                accessToken: data.access_token,
                userId: data.user.id,
                userName: data.user.first_name,
            };

            Object.entries(userInfo).forEach(([key, value]) => {
                Cookies.set(key, value, { expires: 30, sameSite: 'None', secure: true });
                localStorage.setItem(key, value);
            });

            navigate("/onboarding-process", { state: { initialRegistry: true } });
        } catch (error) {
            if (error.message === "The email has already been taken.") {
                setValidation((prevValidation) => ({
                    ...prevValidation,
                    email: (
                        <>
                            The email address you entered is already in use.&nbsp;
                            <Link to="#" className="invalid-request" onClick={handleRequestPassword}>Request password</Link> to log in.
                        </>
                    ),
                }));
                setLoading(false);
            } else {
                showToast('Error. Please try again', "error");
                setLoading(false);
            }
        } finally {
            setLoading(false);
        }
    }
  };

  const googleSignup = async () => {
    try {
      const response = await GoogleAuth.signIn({
        prompt: "select_account",
      });
      handleGoogleSignup(response);
    } catch (error) {
      console.error("Error during Google signup:", error);
    }
  };
  
  const handleGoogleSignup = async (response) => {
    try {
      const id_token = response.authentication.accessToken;
      const apiResponse = await api.googleLogin(id_token);
      const { access_token, user } = apiResponse.user.original;
      const userType = apiResponse.user.original.userType;
      const registryCreated = apiResponse.user.original.registry_created === 'true';
  
      Object.entries({
        accessToken: access_token,
        userId: user.id,
        userName: user.first_name,
        userEmail: user.email,
      }).forEach(([key, value]) => {
        Cookies.set(key, value, { expires: 30, sameSite: 'None', secure: true });
        localStorage.setItem(key, value);
      });
  
      if (userType === 'new') {
        navigate('/onboarding-process', { state: { initialRegistry: true } });
      } else if (userType === 'old') {
        if (registryCreated) {
          const registryList = user.registry;
          const primaryRegistry = registryList[0];
          const processComplete = primaryRegistry.process_complete === 'true';
          let registryInfo;
  
          if (processComplete) {
            registryInfo = {
              registryId: primaryRegistry.id,
              registryTypeId: primaryRegistry.registry_type_id,
              registryTypeName: primaryRegistry.registry_type_name,
              registryPublic: primaryRegistry.public,
            };
  
            if (registryList.length > 1) {
              navigate("/all-registry");
            } else if (registryList.length === 1) {
              navigate("/dashboard");
            }
          } else {
            registryInfo = {
              stagingRegistryId: primaryRegistry.id,
              stagingRegistryTypeId: primaryRegistry.registry_type_id,
            };
  
            navigate("/onboarding-process?processStep=2", { state: { initialRegistry: true } });
            showToast("Please complete your registry setup");
          }
  
          Object.entries(registryInfo).forEach(([key, value]) => {
            Cookies.set(key, value, { expires: 30, sameSite: 'None', secure: true });
            localStorage.setItem(key, value);
          });
        } else {
          navigate("/onboarding-process", { state: { initialRegistry: true } });
          showToast("Please Select a registry");
        }
      }
    } catch (error) {
      console.error('Error during Google login:', error);
      showToast("Failed to Google login");
    } finally {
      window.scrollTo(0, 0);
    }
  };
  
  const appleSignup = async () => {
    let options = {
      clientId: 'in.quickregistry.applesignin',
      redirectURI: 'https://quickregistry-e2113.firebaseapp.com//auth/handler',
      scopes: 'email',
      state: '12345',
    };
  
    try {
      const appleResponse = await SignInWithApple.authorize(options);
      
      const identityToken = appleResponse.response.identityToken;
      if (!identityToken) {
        throw new Error("Identity token is missing from the Apple login response.");
      }
  
      const givenName = appleResponse.response.givenName || "Apple";
      const familyName = appleResponse.response.familyName || "User";
  
      const apiResponse = await api.appleLogin(identityToken, null, givenName, familyName);
      handleAppleSignup(apiResponse);
  
    } catch (error) {
      console.error("Error during Apple Signup: ", error);
    }
  };
  
  const handleAppleSignup = async (apiResponse) => {
    try {
      const access_token = apiResponse.access_token;
      const user = apiResponse.user;
      const userType = apiResponse.userType;
      const registryCreated = apiResponse.registry_created === 'true';
  
      Object.entries({
        accessToken: access_token,
        userId: user.id,
        userName: user.first_name,
        userEmail: user.email,
      }).forEach(([key, value]) => {
        Cookies.set(key, value, { expires: 30, sameSite: 'None', secure: true });
        localStorage.setItem(key, value);
      });
  
      if (userType === 'new') {
        navigate('/onboarding-process', { state: { initialRegistry: true } });
      } else if (userType === 'old') {
        if (registryCreated) {
          const registryList = user.registry;
          const primaryRegistry = registryList[0];
          const processComplete = primaryRegistry.process_complete === 'true';
          let registryInfo;
  
          if (processComplete) {
            registryInfo = {
              registryId: primaryRegistry.id,
              registryTypeId: primaryRegistry.registry_type_id,
              registryTypeName: primaryRegistry.registry_type_name,
              registryPublic: primaryRegistry.public,
            };
  
            if (registryList.length > 1) {
              navigate("/all-registry");
            } else if (registryList.length === 1) {
              navigate("/dashboard");
            }
          } else {
            registryInfo = {
              stagingRegistryId: primaryRegistry.id,
              stagingRegistryTypeId: primaryRegistry.registry_type_id,
            };
  
            navigate("/onboarding-process?processStep=2", { state: { initialRegistry: true } });
            showToast("Please complete your registry setup");
          }
  
          Object.entries(registryInfo).forEach(([key, value]) => {
            Cookies.set(key, value, { expires: 30, sameSite: 'None', secure: true });
            localStorage.setItem(key, value);
          });
        } else {
          navigate("/onboarding-process", { state: { initialRegistry: true } });
          showToast("Please Select a registry");
        }
      }
    } catch (error) {
      console.error('Error during Apple login:', error);
      showToast("Failed to Apple login");
    } finally {
      window.scrollTo(0, 0);
    }
  };  

  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <main className="main">
          <article>
            <section className="onboarding-process">
              <div className="container">
                <div className="row">
                  <div className="process-box">
                    <div className="col-md-12 text-center">
                    {fromiOSDevice && <div className="spacing-mid"></div>}
                      <div className="spacing-mid"></div>
                      <Link to="/" className="navbar-logo">
                        <img
                          src="assets/images/logo.png"
                          alt="logo"
                          loading="lazy"
                        />
                      </Link>
                      <div className="spacing-mid"></div>
                    </div>
                    <div className="col-md-12 text-center">
                      <h2>Let's create your registry together.</h2>
                      <div className="spacing-mid"></div>
                    </div>
                    <div className="col-md-12">
                      <form className="signup-form" noValidate>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="user-box">
                              <input
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleInputChange}
                                onKeyPress={(e) => e.key === 'Enter' && handleSignup(e)}
                                className={`form-control ${validation.firstName ? "is-invalid" : ""}`}
                                autoComplete="offAuto"
                                required
                              />
                              <label>First Name</label>
                              <p className="validation invalid-feedback"> {validation.firstName} </p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="user-box">
                              <input
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                                onKeyPress={(e) => e.key === 'Enter' && handleSignup(e)}
                                className={`form-control ${validation.lastName ? "is-invalid" : ""}`}
                                autoComplete="offAuto"
                                required
                              />
                              <label>Last Name</label>
                              <p className="validation invalid-feedback"> {validation.lastName} </p>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="user-box">
                              <input
                                type="text"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                onKeyPress={(e) => e.key === 'Enter' && handleSignup(e)}
                                className={`form-control ${validation.email ? "is-invalid" : ""}`}
                                autoComplete="offAuto"
                                required
                              />
                              <p className="validation invalid-feedback"> {validation.email} </p>
                              <label>Email Address</label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="user-box">
                              <input
                                type={showPassword ? "text" : "password"}
                                name="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                onKeyPress={(e) => e.key === 'Enter' && handleSignup(e)}
                                className={`mb-2 form-control ${validation.password ? "is-invalid" : ""}`}
                                maxLength={20}
                                required
                              />
                              <label>Password</label>
                              <Link
                                className="password-toggle-icon signup-picon end-0 me-4"
                                onClick={togglePasswordVisibility}
                              >
                                {showPassword ? (
                                  <i className="las la-eye-slash fs-4"></i>
                                ) : (
                                  <i className="las la-eye fs-4"></i>
                                )}
                              </Link>
                              <p className="validation-signup invalid-feedback"> {validation.password} </p>
                              <span className="alert-msg"> Use 8-20 alphanumeric and special characters (@,!,#) </span>
                              <HtmlTooltip
                                title={
                                  <>
                                    <span>A strong password keeps your account secure.</span>
                                  </>
                                }
                                placement={isPortrait ? "bottom" : "right"}
                                interactive
                              >
                                <InfoIcon src="assets/images/icon/info-icon.svg" alt="info-icon" className="info-icon" />
                              </HtmlTooltip>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="user-box">
                              <div className="checkbox checkbox-container checkbox-size">
                                <input
                                  type="checkbox"
                                  id="termsCheckbox"
                                  name="termsAccepted"
                                  checked={formData.termsAccepted}
                                  onChange={handleInputChange}
                                  onKeyPress={(e) => e.key === 'Enter' && handleSignup(e)}
                                  className={`termAndCondition ${validation.termsAccepted ? " is-invalid" : ""}`}
                                  required
                                />
                                <label
                                  className="checkbox-text mobile-checkbox-text"
                                >
                                  I accept the website's&nbsp;
                                  <Link to="/terms&conditions" onClick={() => window.scrollTo(0, 0)}>Terms & Conditions</Link> and&nbsp;
                                  <Link to="/privacy-policy" onClick={() => window.scrollTo(0, 0)}>Privacy Policy</Link>
                                </label>
                              </div>
                              <p className="validation-signup text-danger">{validation.termsAccepted}</p>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="user-box">
                            <button
                              className="btn btn-primary form-btn main-btn mt-2"
                              onClick={handleSignup}
                              disabled={loading}
                            >
                              {loading ? (
                                <>
                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp;Creating Registry...
                                </>
                              ) : (
                                "Create A Registry"
                              )}
                            </button>
                            </div>
                            <div className="user-box or text-center">
                              <p>or</p>
                            </div>
                            <div className="user-box text-center">
                              <div className="social-btn">
                                <Link to="#" className="social-login-btn" onClick={() => googleSignup()}>
                                  <img src="/assets/images/icon/google-icon.svg" alt="Google icon" className="me-2" style={{ width: '22px', height: '22px' }} />
                                  Sign up with Google
                                </Link>
                              </div>
                              {fromiOSDevice && (
                                <div className="social-btn apple-social-btn">
                                  <Link to="#" className="social-login-btn" onClick={() => appleSignup()}>
                                    <img src="/assets/images/icon/apple-icon.svg" alt="Apple icon" className="me-2 apple-icon" style={{ width: '22px', height: '22px' }} />
                                    Sign up with Apple
                                  </Link>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      <button type="submit" className="visually-hidden">Submit</button>
                      </form>
                      <div className="spacing"></div>
                    </div>
                    <div className="col-md-12">
                      <div className={`back-btn-text ${fromiOSDevice && 'ios-area'}`}>
                        <p>
                          <Link className="back-button" onClick={() => navigate(-1)}>
                            <i className="la la-angle-left"></i> Back
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className={`login-text ${fromiOSDevice && 'ios-area'}`}>
                        <p>
                          Already have an account?&nbsp;
                          {fromMobileApp ? (
                            <Link
                              to="/m-login"
                              className="login-already"
                            >
                              Log In
                            </Link>
                          ) : (
                            <Link
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#Login"
                              className="login-already"
                            >
                              Log In
                            </Link>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row"></div>
              </div>
            </section>
          </article>
        </main>
      </div>
      <LoginModal />
    </div>
  );
};

export default Signup;
