import React from "react";
import { Link } from 'react-router-dom';

const BrandSection = () => {
  return (
    <main className="main">
      <article>
        <section className="py-5 light-bg" data-aos="fade-up">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="brands-box pt-4">
                  <h1 className="my-4 heading-med">
                    Experience the Simplicity of receiving gifts!"
                  </h1>
                  <p className="my-5">
                    Combine all your preferred store wish lists into one convenient registry.
                  </p>
                </div>
              </div>

              <div className="col-lg-7">
                <div className="brand-logo pb-4">
                  <div className="row">

                    <div className="brand-item">
                      <Link to="https://www.firstcry.com" target="_blank" rel="noopener noreferrer">
                        <img src="assets/images/firstcry.png" alt="" />
                      </Link>
                    </div>

                    <div className="brand-item">
                      <Link to="https://littlemuffet.com" target="_blank" rel="noopener noreferrer">
                        <img src="assets/images/little-muffet.png" alt=""/>
                      </Link>
                    </div>

                    <div className="brand-item">
                      <Link to="https://www.mumkins.in" target="_blank" rel="noopener noreferrer">
                        <img src="assets/images/mumkins.png" alt="" />
                      </Link>
                    </div>

                    {/* <div className="brand-item">
                      <Link to="https://www.hopscotch.in" target="_blank" rel="noopener noreferrer">
                        <img src="assets/images/hopscotch.png" alt="" />
                      </Link>
                    </div> */}

                    <div className="brand-item">
                      <Link to="https://www.rforrabbit.com" target="_blank" rel="noopener noreferrer">
                        <img src="assets/images/r-for-rabbit.png" alt="" />
                      </Link>
                    </div>

                    <div className="brand-item">
                      <Link to="https://www.mothercare.in" target="_blank" rel="noopener noreferrer">
                        <img src="assets/images/mothercare.png" alt="" />
                      </Link>
                    </div>

                    <div className="brand-item">
                      <Link to="https://www.carters.com" target="_blank" rel="noopener noreferrer">
                        <img src="assets/images/carters.png" alt="" />
                      </Link>
                    </div>

                    <div className="brand-item">
                      <Link to="https://www2.hm.com/en_in/kids.html" target="_blank" rel="noopener noreferrer">
                        <img src="assets/images/h&m.png" alt="" />
                      </Link>
                    </div>

                    <div className="brand-item">
                      <Link to="https://www.zara.com/in/en/kids-mkt1.html" target="_blank" rel="noopener noreferrer">
                        <img src="assets/images/zara.png" alt="" />
                      </Link>
                    </div>

                    <div className="brand-item">
                      <Link to="https://www.hamleys.in" target="_blank" rel="noopener noreferrer">
                        <img src="assets/images/hamleys.png" alt="" />
                      </Link>
                    </div>

                    <div className="brand-item">
                      <Link to="https://www.amazon.in/kids-fashion/b?ie=UTF8&node=9361420031" target="_blank" rel="noopener noreferrer">
                        <img src="assets/images/amazon.png" alt="logo" />
                      </Link>
                    </div>

                    <div className="brand-item">
                      <Link to="https://www.myntra.com/kids" target="_blank" rel="noopener noreferrer">
                        <img src="assets/images/myntra.png" alt="" />
                      </Link>
                    </div>

                    <div className="brand-item">
                      <Link to="https://www.ajio.com/shop/kids" target="_blank" rel="noopener noreferrer">
                        <img src="assets/images/ajio.png" alt="" />
                      </Link>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
    </main>
  );
};

export default BrandSection;
