import React, { useState } from "react";
import Header from "../../components/common/Header";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import { showToast } from "../../utils/toastUtils";
import api from "../../services/api";

const FeedbackPage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [validation, setValidation] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
  
    if (name === "phoneNumber") {
      newValue = value.replace(/\D/g, "");
    }
  
    setFormData({
      ...formData,
      [name]: newValue,
    });

    setValidation((prevValidation) => ({
      ...prevValidation,
      [name]: "",
    }));
  };

  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
  
    let isValid = true;
    const newValidation = { ...validation };
  
    if (!formData.firstName) {
      newValidation.firstName = "Please enter your first name";
      isValid = false;
    }
  
    if (!formData.lastName) {
      newValidation.lastName = "Please enter your last name";
      isValid = false;
    }
  
    if (!formData.email) {
      newValidation.email = "Please enter your email address";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newValidation.email = "Please enter a valid email address";
      isValid = false;
    } else {
      newValidation.email = "";
    }
  
    if (!formData.phoneNumber) {
      newValidation.phoneNumber = "Please enter your phone number";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
      newValidation.phoneNumber = "Please enter a valid 10-digit phone number";
      isValid = false;
    } else {
      newValidation.phoneNumber = "";
    }
  
    if (!formData.message) {
      newValidation.message = "Please enter your feedback message";
      isValid = false;
    }
  
    setValidation(newValidation);
  
    if (isValid) {
      const requestData = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        mobile_no: formData.phoneNumber,
        message: formData.message,
      };

      try {
        await api.submitFeedback(requestData);
        showToast("Your feedback submitted successfully!", "success");

        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          message: "",
        });
      } catch (error) {
        console.error("Error submitting feedback:", error);
        showToast("Failed to submit feedback", "error");
      }
    }
  };

  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          <Navbar />
        </header>
        <main className="main">
          <article>
            <section className="py-5">
              <div className="container form-heading text-justify">
                <p className="aboutus-heading text-center">Feedback</p>
                <p className="mt-3">
                  We value your feedback! If you have any suggestions, comments,
                  or ideas that you'd like to share with us, please feel free to
                  do so below. Your input helps us continually improve our
                  services and better meet your needs.
                </p>
              </div>
              <div className="col-md-12">
                <form className="common-form" noValidate>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="user-box">
                        <input
                          type="text"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleInputChange}
                          onKeyPress={(e) =>
                            e.key === "Enter" && handleFeedbackSubmit(e)
                          }
                          className={`form-control ${
                            validation.firstName ? "is-invalid" : ""
                          }`}
                          required
                        />
                        <label>First Name</label>
                        <p className="validation invalid-feedback">{validation.firstName}</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="user-box">
                        <input
                          type="text"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleInputChange}
                          onKeyPress={(e) =>
                            e.key === "Enter" && handleFeedbackSubmit(e)
                          }
                          className={`form-control ${
                            validation.lastName ? "is-invalid" : ""
                          }`}
                          required
                        />
                        <label>Last Name</label>
                        <p className="validation invalid-feedback">{validation.lastName}</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="user-box">
                        <input
                          type="text"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          onKeyPress={(e) =>
                            e.key === "Enter" && handleFeedbackSubmit(e)
                          }
                          className={`form-control ${
                            validation.email ? "is-invalid" : ""
                          }`}
                          required
                        />
                        <label>Email</label>
                        <p className="validation invalid-feedback">{validation.email}</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="user-box">
                        <input
                          type="tel"
                          name="phoneNumber"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                          onKeyPress={(e) =>
                            e.key === "Enter" && handleFeedbackSubmit(e)
                          }
                          className={`form-control ${
                            validation.phoneNumber ? "is-invalid" : ""
                          }`}
                          maxLength={10}
                          required
                        />
                        <label>Phone Number</label>
                        <p className="validation invalid-feedback">{validation.phoneNumber}</p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="my-2">Message</label>
                      <div className="user-box">
                        <textarea
                          id="message"
                          name="message"
                          value={formData.message}
                          onChange={handleInputChange}
                          placeholder="Please give your feedback"
                          className={`form-control ${validation.message ? "is-invalid" : ""}`}
                        />
                        <p className="validation invalid-feedback m-2">{validation.message}</p>
                      </div>
                    </div>
                    <div className="mt-4 mb-5">
                      <button
                        className="btn btn-primary form-btn static-btn"
                        onClick={handleFeedbackSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </article>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default FeedbackPage;
