import { toast } from 'react-hot-toast';
import { getRegistryCredentials } from './registryUtils';

const { fromMobileApp } = getRegistryCredentials();

const toastStyle = {
  border: '1px solid #6E3265',
  padding: '16px',
  color: '#6E3265',
  backgroundColor: '#fdf8fb',
  maxWidth: 500,
  fontSize: fromMobileApp ? '14px' : '15px'
};

const toastIconTheme = {
  primary: '#6E3265',
  secondary: '#FFFAEE',
};

export const showToast = (message, type) => {
  let position = fromMobileApp ? 'bottom-center' : 'top-center';

  if (type === 'success') {
    toast.success(message, {
      style: toastStyle,
      iconTheme: toastIconTheme,
      position: position,
    });
  } else if (type === 'error') {
    toast.error(message, {
      style: toastStyle,
      iconTheme: toastIconTheme,
      position: position,
    });
  } else {
    toast(message, {
      style: toastStyle,
      iconTheme: toastIconTheme,
      position: position,
    });
  }
};