/* global $ */
import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { showToast } from '../../utils/toastUtils';
import Header from "../../components/common/Header";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import api from "../../services/api";
import Cropper from 'react-easy-crop';
import 'react-easy-crop/react-easy-crop.css';
import { getRegistryCredentials } from "../../utils/registryUtils";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import ShippingInfoModal from "../../components/dashboard/modal/ShippingInfoModal";
import SendReminderModal from "../../components/dashboard/modal/SendReminderModal";
import EditDescriptionModal from "../../components/dashboard/modal/EditDescriptionModal";

const ViewAsVisitorPage = () => {
  const navigate = useNavigate();
  const { publicKey } = useParams();
  const [hasPassword, setHasPassword] = useState(false);
  const [visitorData, setVisitorData] = useState(null);
  const [userAnswers, setUserAnswers] = useState(null);
  const [registryData, setRegistryData] = useState(null);
  const [yourName, setYourName] = useState('');
  const [yourPhoneNumber, setYourPhoneNumber] = useState('');
  const [visitorPassword, setVisitorPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isYourNameValid, setIsYourNameValid] = useState(true);
  const [isYourPhoneNumberValid, setIsYourPhoneNumberValid] = useState(true);
  const [isVisitorPasswordValid, setIsvisitorPasswordValid] = useState(true);
  const [verifyingPassword, setVerifyingPassword] = useState(false);
  const [requestingPassword, setRequestingPassword] = useState(false);
  const [passwordProtected, setPasswordProtected] = useState(false);
  const [isGridMode, setIsGridMode] = useState(true);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const { accessToken, registryId, fromiOSDevice } = getRegistryCredentials();
  const [filterOptions, setFilterOptions] = useState({
    platform_name: '',
    item_category_id: '',
    sort_by: '',
  });

  useEffect(() => {
    const handleGiftView = (e) => {
      const button = e.target.closest('button');

      if (button.classList.contains('list-btn')) {
        $('.full-list ul').addClass('view-list').removeClass('grid');
        $('.product-img img').css({ 'height': '140px', 'objectFit': 'contain' });
      } else if (button.classList.contains('grid')) {
        $('.full-list ul').addClass('grid').removeClass('view-list');
        $('.product-img img').css({ 'height': '250px', 'object-fit': 'contain' });
      }
    };

    $(document).on('click', '.grid-buttons button', handleGiftView);
  }, []);
  
  const handleToggleViewMode = (isGrid) => {
    setIsGridMode(isGrid);
  };

  const handleTogglePassword = () => {
    setHasPassword(!hasPassword);
    clearVisitorDetails();
  };

  const getUserAnswerByIndex = (userAnswers, index) => {
    return userAnswers[index]?.question_answer;
  };

  useEffect(() => {
    fetchVisitorData();
    // eslint-disable-next-line
  }, [publicKey, filterOptions]);

  const fetchVisitorData = async () => {
    try {
      const filteredOptions = {};

      if (filterOptions.item_category_id !== '') {
        filteredOptions.item_category_id = filterOptions.item_category_id;
      }
      if (filterOptions.platform_name !== '') {
        filteredOptions.platform_name = filterOptions.platform_name;
      }
      if (filterOptions.sort_by !== '') {
        filteredOptions.sort_by = filterOptions.sort_by;
      }

      const response = await api.getVisitorData(publicKey, null, filteredOptions);
      setVisitorData(response);
      setPasswordProtected(response.password_protected);
      setUserAnswers(response.user_answers);
      setRegistryData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching visitor data:", error);
      setLoading(true);
    }
  };

  const handleNameChange = (e) => {
    setYourName(e.target.value);
    setIsYourNameValid(true);
  };

  const handlePhoneNumberChange = (e) => {
    const inputValue = e.target.value;
    const numericInput = inputValue.replace(/\D/g, '');
    setYourPhoneNumber(numericInput);
    setIsYourPhoneNumberValid(true);
  };

  const handleRequestPassword = async (e) => {
    e.preventDefault();

    if (yourName.trim() === "" && yourPhoneNumber.trim() === "") {
      setIsYourNameValid(false);
      setIsYourPhoneNumberValid(false);
      showToast("Please enter your name and phone number");
      return;
    }

    if (yourName.trim() === "") {
      setIsYourNameValid(false);
      showToast("Please enter your name");
      return;
    }

    if (yourPhoneNumber.trim() === "") {
      setIsYourPhoneNumberValid(false);
      showToast("Please enter your phone number");
      return;
    }

    const phoneNumberPattern = /^[0-9]{10}$/;
    if (!phoneNumberPattern.test(yourPhoneNumber)) {
      setIsYourPhoneNumberValid(false);
      showToast("Please enter a valid 10-digit phone number");
      return;
    }

    try {
      setRequestingPassword(true);
      await api.requestVisitorPassword(yourName, yourPhoneNumber, publicKey);
      setHasPassword(false);
      showToast("Password requested successfully", "success");
      setHasPassword(false);
      clearVisitorDetails();
    } catch (error) {
      console.error("Error requesting password:", error);
      showToast("Failed to request password. Please try again.", "error");
    } finally {
      setRequestingPassword(false);
    }
  };

  const handlePasswordChange = (e) => {
    setVisitorPassword(e.target.value);
    setIsvisitorPasswordValid(true);
  };

  const handleVisitorPassword = async (e) => {
    e.preventDefault();
    if (visitorPassword.trim() === "") {
      showToast("Please enter a visitor password");
      setIsvisitorPasswordValid(false);
      return;
    }
    try {
      setVerifyingPassword(true);
      const response = await api.getVisitorData(publicKey, visitorPassword);

      setVisitorData(response);
      setUserAnswers(response.user_answers);
      setPasswordProtected(response.password_protected);
      setRegistryData(response.data);
    } catch (error) {
      if (error.message === "Invalid Password") {
        showToast("Invalid password. Please try again");
        setIsvisitorPasswordValid(false);
      } else {
        console.error('Error verifying password:', error);
        showToast(`Failed to verify password`, "error");
      }
    } finally {
      setVerifyingPassword(false);
    }
  };

  const clearVisitorDetails = () => {
    setYourName('');
    setYourPhoneNumber('');
    setVisitorPassword('');
    setIsYourNameValid(true);
    setIsYourPhoneNumberValid(true);
    setIsvisitorPasswordValid(true);
  };

  const getFilterValue = () => {
    const { item_category_id, platform_name } = filterOptions;
    if (item_category_id !== '') {
      return `category:${item_category_id}`;
    } else if (platform_name !== '') {
      return `store:${platform_name}`;
    } else {
      return '';
    }
  };

  const handleFilterChange = (e) => {
    const { value } = e.target;
    const [type, filterValue] = value.split(':');
    let updatedOptions = { ...filterOptions };

    switch (type) {
      case 'category':
        updatedOptions.item_category_id = filterValue;
        updatedOptions.platform_name = '';
        break;
      case 'store':
        updatedOptions.platform_name = filterValue;
        updatedOptions.item_category_id = '';
        break;
      default:
        updatedOptions = {
          platform_name: '',
          item_category_id: '',
          sort_by: '',
        };
    }

    setFilterOptions(updatedOptions);
  };

  const handleSortChange = (e) => {
    const { value } = e.target;
    setFilterOptions({ ...filterOptions, sort_by: value });
  };

  const handleiOSUpload = async () => {
    try {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Photos,
        quality: 100
      });

      const imageUrl = photo.dataUrl;
      setSelectedImage(imageUrl);
      $("#image-modal").modal("show");
    } catch (error) {
      console.error('Error selecting photo', error);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      if (validImageTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImage(reader.result);
          $('#image-modal').modal('show');
        };
        reader.readAsDataURL(file);
      } else {
        showToast("Please upload a valid image file (e.g., PNG, JPEG, JPG)", "error");
      }
    }
  };

  const getCroppedImg = (imageSrc, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const image = new Image();
    image.src = imageSrc;

    return new Promise((resolve, reject) => {
      image.onload = () => {
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }
          blob.name = fileName;
          const fileUrl = window.URL.createObjectURL(blob);
          resolve(fileUrl);
        }, 'image/jpeg');
      };
    });
  };

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropSave = async () => {
    setImageLoading(true);
    const croppedImgUrl = await getCroppedImg(selectedImage, croppedAreaPixels, 'cropped.jpg');
    const reader = new FileReader();
    reader.readAsDataURL(await fetch(croppedImgUrl).then(res => res.blob()));
    reader.onloadend = async () => {
      const base64Image = reader.result;
      try {
        await api.setRegistryImage(accessToken, registryId, base64Image);
        showToast("Profile image updated successfully", "success");
        fetchVisitorData();
        setImageLoading(false);
        $('#image-modal').modal('hide');
      } catch (error) {
        console.error("Error uploading image:", error);
        showToast("Failed to upload image. Please try again.", "error");
      }
    };
  };

  const handleCancelCrop = () => {
    $('#image-modal').modal('hide');
    setSelectedImage(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  const renderImageModal = () => (
    <div className="modal fade" id="image-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog common-modal-popup">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body row">
            <div className="crop-container">
              <Cropper
                image={selectedImage}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                cropShape="round"
              />
            </div>
            <div className="slider-container">
              <input
                type="range"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => setZoom(e.target.value)}
                className="zoom-range"
              />
            </div>
          </div>
          <div className="user-detials-btn text-center mb-4">
            <button type="button" className="common-modal-btn common-btn" onClick={handleCancelCrop}>CANCEL</button>
            <button
              type="button"
              className="btn common-modal-btn common-btn active-btn"
              onClick={handleCropSave}
              disabled={imageLoading}
            >
              {imageLoading ? "SAVING..." : "SAVE"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const handleEditDescription = () => {
    $('#description-modal').modal('show');
  };

  const handleBuyNow = (itemId, regId) => {
    navigate(`/view-product?itemId=${itemId}&regId=${regId}`);
    window.scrollTo(0, 0)
  };

  function formatDate(dateString) {
    const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    return formattedDate.toUpperCase();
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSendReminder = () => {
    $("#quick-reminder").modal("show");
  }

  const handleShippingInfo = () => {
    $("#shipping-info").modal("show");
  }

  return (
    <div className="wrapper animsition">
      {loading ? (
        <div className="custom-loader-container fs-5">
          <div className="spinner-border custom-loader" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="wrapper-inner">
          <header className="header">
            <Header />
            <Navbar registryData={registryData} visitorData={visitorData} />
          </header>
          <main className="main">
            <article>
              {passwordProtected && !registryData ? (
                <section className="py-5">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="visitor-dashboard mobile-visitor-board text-center">
                          <div className="user-dashboard">
                            <div className="reset-info mt-3 mb-4">
                              {visitorData && (
                                <>
                                  <h4>
                                    {visitorData.user_first_name}
                                    {userAnswers && getUserAnswerByIndex(userAnswers, 1) && (<> & {getUserAnswerByIndex(userAnswers, 1).split(' ')[0]}</>)}
                                    's gift list
                                  </h4>
                                </>
                              )}
                              <p className="mt-4 fs-6">{!hasPassword ? "Requires a password" : "To request the password please add:"}</p>
                            </div>
                            {!hasPassword ? (
                              <div className="user-box visitor-user-box">
                                <input
                                  type={showPassword ? "text" : "password"}
                                  name="visitor-password"
                                  value={visitorPassword}
                                  onChange={handlePasswordChange}
                                  className={`form-control ${isVisitorPasswordValid ? '' : 'is-invalid'}`}
                                  onKeyPress={(e) => e.key === 'Enter' && handleVisitorPassword(e)}
                                  maxLength={20}
                                  required
                                />
                                <label>Password</label>
                                <Link
                                  className="password-toggle-icon password-icon position-absolute end-0 top-50 me-3"
                                  onClick={togglePasswordVisibility}
                                >
                                  {showPassword ? (
                                    <i className="las la-eye-slash fs-4"></i>
                                  ) : (
                                    <i className="las la-eye fs-4"></i>
                                  )}
                                </Link>
                              </div>
                            ) : (
                              <>
                                <div className="user-box visitor-user-box">
                                  <input
                                    name="your-name"
                                    value={yourName}
                                    onChange={handleNameChange}
                                    className={`form-control ${isYourNameValid ? '' : 'is-invalid'}`}
                                    onKeyPress={(e) => e.key === 'Enter' && handleRequestPassword(e)}
                                    required
                                  />
                                  <label>Your Name</label>
                                </div>
                                <div className="user-box visitor-user-box">
                                  <input
                                    name="your-phone-number"
                                    value={yourPhoneNumber}
                                    onChange={handlePhoneNumberChange}
                                    className={`form-control ${isYourPhoneNumberValid ? '' : 'is-invalid'}`}
                                    onKeyPress={(e) => e.key === 'Enter' && handleRequestPassword(e)}
                                    maxLength={10}
                                    required
                                  />
                                  <label>Your Phone Number</label>
                                </div>
                              </>
                            )}
                            {!hasPassword ? (
                              <button
                                type="button"
                                className="btn btn-primary view-gift-btn"
                                onClick={handleVisitorPassword}
                                disabled={verifyingPassword}
                              >
                                {verifyingPassword ? (
                                  <>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp;Verifying...
                                  </>
                                ) : (
                                  'VIEW THE GIFT LIST'
                                )}
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-primary view-gift-btn"
                                onClick={handleRequestPassword}
                                disabled={requestingPassword}
                              >
                                {requestingPassword ? (
                                  <>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp;Requesting...
                                  </>
                                ) : (
                                  'REQUEST PASSWORD'
                                )}
                              </button>
                            )}
                            <div className="primary-button text-center mb-3 mt-4">
                              <button type="button" onClick={handleTogglePassword}>
                                {!hasPassword ? "I don't have the password" : "I have the password"} </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              ) : (
                <>
                  <section className="visitor">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="visitor-section">
                            {visitorData && (
                              <>
                                <div className="visitor-profile me-3">
                                  <img
                                    src={visitorData.profile_image || "/assets/images/profile_picture.png"}
                                    alt=""
                                    className="profile-picture"
                                  />
                                  {accessToken && visitorData && visitorData.registry_id === parseInt(registryId) && (
                                    <span className="edit-profile-icon">
                                      <i
                                        className="la la-pencil"
                                        role="button"
                                        onClick={fromiOSDevice ? handleiOSUpload : () => document.getElementById('file-upload').click()}
                                      ></i>
                                      {!fromiOSDevice && (
                                        <input
                                          type="file"
                                          id="file-upload"
                                          style={{ display: 'none' }}
                                          onChange={handleImageUpload}
                                          accept="image/png, image/jpeg, image/jpg"
                                        />
                                      )}
                                    </span>
                                  )}
                                </div>
                                <div className="visitor-section-name">
                                  <h2>
                                    {visitorData.user_first_name} {visitorData.user_last_name}
                                    {userAnswers && getUserAnswerByIndex(userAnswers, 1) && (<> & {getUserAnswerByIndex(userAnswers, 1)}</>)}
                                  </h2>
                                  <h5>
                                    {visitorData?.registry_type_name || "Quick Registry"}
                                    <br /><br />
                                    {userAnswers && getUserAnswerByIndex(userAnswers, 2) && (formatDate(getUserAnswerByIndex(userAnswers, 2)))}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section>
                    <div className="visitor-desc-container">
                      <p className="visitor-description">
                        {visitorData && visitorData.description !== null
                          ? visitorData.description
                          : (accessToken && visitorData && visitorData.registry_id === parseInt(registryId) && (
                            <span className="add-description">
                              Add description here...
                            </span>
                          ))}
                        {accessToken && visitorData && visitorData.registry_id === parseInt(registryId) && (
                          <span className="edit-desc-icon" role="button" onClick={handleEditDescription}>
                            <i className="la la-pencil"></i>
                          </span>
                        )}
                      </p>
                    </div>
                  </section>

                  <section className="py-5">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="dashboard">
                            {visitorData && visitorData.lifafa_details && (
                              <>
                                <div className="lifafa-bar text-center">
                                  <h4 className="visit-heading">LIFAFA</h4>
                                </div>
                                <div className="user-dashboard lifafa-section mb-5">
                                  <div className="dashboard-content">
                                    <div className="lifafa-info">
                                      <p>To send a ₹ Lifafa <span><i className="la la-envelope fs-5"></i></span>, simply scan the QR code using any UPI-enabled app. This will allow you to quickly and easily send a gift to your loved ones. The Lifafa is a perfect way to share your love and care, making every occasion more special and memorable. Whether it's a birthday, anniversary, or just a simple gesture, sending a Lifafa brings joy and happiness to those you care about.</p>
                                    </div>
                                    <div className="qr-image">
                                      <img src={visitorData?.lifafa_details?.qr_code} alt="qr-code" className="lifafa-image" />
                                      <p className="mt-2"><b>{visitorData?.lifafa_details?.upi_id}</b></p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="lifafa-bar text-center">
                              <h4 className="visit-heading">GIFT LIST</h4>
                            </div>
                            <div className="user-dashboard">
                              <div className="top-filter visitor-filter">
                                <div className="filter-box">
                                  <div className="filter-part">
                                    <label htmlFor="categorySelect">Filter:</label>
                                    <div className="select-wrapper">
                                      <select
                                        name="category"
                                        id="categorySelect"
                                        className="form-control"
                                        value={getFilterValue()}
                                        onChange={handleFilterChange}
                                      >
                                        <option value="">All Gifts</option>
                                        {visitorData && (
                                          <>
                                            {visitorData.item_categories
                                              .filter(category => category.name !== "All Gifts")
                                              .sort((a, b) => a.name.localeCompare(b.name))
                                              .map(category => (
                                                <option key={category.id} value={`category:${category.id}`}>
                                                  {category.name}
                                                </option>
                                              ))}
                                            <optgroup label="BY STORE">
                                              {visitorData.platform_names
                                                .sort((a, b) => a.platform_name.localeCompare(b.platform_name))
                                                .map((store, index) => (
                                                  <option key={index} value={`store:${store.platform_name}`}>
                                                    {store.platform_name}
                                                  </option>
                                                ))}
                                            </optgroup>
                                          </>
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="filter-part">
                                    <label htmlFor="">Sort By:</label>
                                    <div className="select-wrapper">
                                      <select
                                        name="sorting"
                                        id="sortingselect"
                                        className="form-control auto-width"
                                        value={filterOptions.sort_by}
                                        onChange={handleSortChange}
                                      >
                                        <option value="">Sort by</option>
                                        <option value="price_high_to_low">Price: high to low</option>
                                        <option value="price_low_to_high">Price: low to high</option>
                                        <option value="favorite">Most desired</option>
                                        {/* <option value="UnpurchasedFirst">Unpurchased First</option>
                                    <option value="by-stores">By Stores</option> */}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="login icons-left primary-button">
                                  <button type="button" className="custom-disabled">EVENT INFO</button>
                                  <button type="button" onClick={handleShippingInfo} >SHIPPING INFO</button>
                                </div>
                                <div className="view-buttons">
                                  <div className="grid-buttons">
                                    <button
                                      className={`grid square-btn${isGridMode ? " active" : ""}`}
                                      onClick={() => handleToggleViewMode(true)}
                                    >
                                      <i className="la la-square-full"></i>
                                    </button>&nbsp;
                                    <button
                                      className={`list-btn square-btn${!isGridMode ? " active" : ""}`}
                                      onClick={() => handleToggleViewMode(false)}
                                    >
                                      <i className="la la-bars"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              {loading ? (
                                <div className="text-center mt-5">
                                  <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                  </div>
                                </div>
                              ) : (
                                <div className="gift-list">
                                  {registryData && registryData.length > 0 ? (
                                    <div className="full-list">
                                      <ul className="grid row mt-4">
                                        {registryData.map((item) => (
                                          <li key={item.id} className="col-xl-4 col-md-6">
                                            <div className="product-details">
                                              <Link to="#" onClick={(e) => {
                                                  e.preventDefault();
                                                  handleBuyNow(item.id, item.registry_id);
                                                }}
                                              >
                                                <div className={`product-img ${item.purchased_qty >= item.required_qty ? 'custom-disabled' : ''}`}>
                                                  {item.image ? (
                                                    <img src={item.image} alt="" className="img-fluid d-block mx-auto" style={{ height: '140px', objectFit: 'contain' }} />
                                                  ) : (
                                                    <img src="/assets/images/no_image_available.png" style={{ height: '140px', objectFit: 'contain' }} alt="" />
                                                  )}
                                                  {item.favorite && (
                                                    <div className="visitor-wishlist">
                                                      <i className="la la-heart"></i>
                                                    </div>
                                                  )}
                                                </div>

                                                <div className="product-desc">
                                                  <p className="clamp-lines">{item.item_name}</p>
                                                  <div className={`product-price ${item.purchased_qty >= item.required_qty ? 'custom-disabled' : ''}`}>
                                                    <h3 className="mb-2">₹ {item.price}</h3>
                                                  </div>
                                                </div>
                                                <div className="product-quantity mb-3">
                                                  <ul>
                                                    <li>Desired: {item.required_qty}</li>
                                                    <li>Purchased: {item.purchased_qty}</li>
                                                  </ul>
                                                  <ul>
                                                    {item.item_details.color && (
                                                      <li>Colour: {item.item_details.color}</li>
                                                    )}
                                                    {item.item_details.size && (
                                                      <li>Size: {item.item_details.size}</li>
                                                    )}
                                                    {!item.item_details.color && !item.item_details.size && <span>&nbsp;</span>}
                                                  </ul>
                                                </div>

                                                <div className="user-box purchased-visitor" onClick={(e) => e.preventDefault()}>
                                                  {item.purchased_qty >= item.required_qty ? (
                                                    <button
                                                      type="button"
                                                      className="btn form-btn purchased-visitor-btn"
                                                      disabled
                                                    >
                                                      PURCHASED
                                                    </button>
                                                  ) : (
                                                    <button
                                                      type="button"
                                                      className="form-btn"
                                                      onClick={(e) => {
                                                        handleBuyNow(item.id, item.registry_id);
                                                      }}
                                                    >
                                                      BUY NOW
                                                    </button>
                                                  )}
                                                </div>
                                                {item.purchased_qty >= 1 && (
                                                  <div className={`product-purchased ${!isGridMode ? "" : "purchased-grid"}`}>
                                                    <span className="success-purchased">
                                                      Successfully marked as purchased!
                                                    </span>
                                                  </div>
                                                )}
                                              </Link>
                                            </div>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  ) : (
                                    <div className="registry-message">
                                      <p className="no-gift-info text-center mb-3">
                                        Whoops, looks like {visitorData.user_first_name}
                                        {userAnswers && userAnswers.find(answer => answer.registry_question_id === 2 && answer.question_answer !== null) && (
                                          <> & {userAnswers.find(answer => answer.registry_question_id === 2).question_answer.split(' ')[0]}</>
                                        )} didn't add any gifts yet.
                                      </p>
                                      <p className="no-gift-info text-center">
                                        <Link className="change-name-color" onClick={handleSendReminder}>CLICK HERE </Link>
                                        to send a quick reminder.
                                      </p>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              )}
            </article>
          </main>
          {renderImageModal()}
          <Footer />
          <ShippingInfoModal />
          <SendReminderModal />
          <EditDescriptionModal
            visitorData={visitorData}
            fetchVisitorData={fetchVisitorData}
          />
        </div>
      )}
    </div>
  );
};

export default ViewAsVisitorPage;