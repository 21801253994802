import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <header className="header">
          <Header />
        </header>
        <main className="main">
          <article>
            <section className="py-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="not-found-container">
                      {/* <div className="gift-images-container mb-5">
                        <img src="/assets/images/not-found.gif" alt="Not Found" className="img-fluid mx-2" />
                        <h3 className="my-3">Oops! You're lost.</h3>
                        <p className="fs-5">The page you are looking for was not found.</p>
                      </div> */}
                      <div className="not-found-details">
                        <h2 className="d-flex justify-content-center align-items-center gap-2 mb-4">
                           <span className="display-1 fw-bold">4</span>
                           <span className="display-1 fw-bold">0</span>
                           {/* <img src="/assets/images/exclamation.png" alt="exclamination" className="img-fluid mx-1" /> */}
                           <span className="display-1 fw-bold">4</span>
                        </h2>
                        <h3 className="mb-3">Oops! You're lost.</h3>
                        <p className="fs-5">The page you are looking for was not found.</p>
                      </div>
                      <div className="user-detials-btn text-center my-5">
                          <button type="button" className="common-btn back-home-btn active-btn" onClick={() => navigate("/")}>Back to Home</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </article>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default NotFoundPage;
