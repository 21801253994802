// eslint-disable-next-line
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { App } from "@capacitor/app";

const AppUrlListener = () => {
  const navigate = useNavigate();

  useEffect(() => {
    App.addListener("appUrlOpen", (event) => {
      const trimmedUrl = event.url.split("share_url=").pop();
      if (trimmedUrl) {
        localStorage.setItem("trimmedUrl", trimmedUrl);
        navigate("/add-gift-modal");
      }
    });

    return () => {
      App.removeAllListeners();
    };
  }, [navigate]);

  return null;
};

export default AppUrlListener;
