import { getRegistryCredentials } from "./registryUtils";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";

export const initializeGoogleAuth = () => {
  const { fromiOSDevice } = getRegistryCredentials();

  GoogleAuth.initialize({
    clientId: fromiOSDevice
      ? "543152696654-qj8rl26riu77kcgbajudq3caufsulaso.apps.googleusercontent.com"
      : "543152696654-9b80fcqvb3jf7tq26rgkd0bueioa0la6.apps.googleusercontent.com",
    scopes: ["profile", "email"],
    grantOfflineAccess: true,
    prompt: "select_account",
  });
};