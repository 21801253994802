/* global $ */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { showToast } from "../../../utils/toastUtils";
import api from "../../../services/api";

const SendReminderModal = () => {
  const defaultMessage = `I just visited your baby registry at QuickRegistry.in, but your gift list was empty. Add some items, so we can shower you—and your new little one—with love.😊`;
  const { publicKey } = useParams();
  const [visitorName, setVisitorName] = useState("");
  const [visitorPhoneNumber, setVisitorPhoneNumber] = useState("");
  const [visitorMessage, setVisitorMessage] = useState(defaultMessage);
  const [remainingChars, setRemainingChars] = useState(500 - defaultMessage.length);
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const validateForm = () => {
      const isPhoneNumberValid = /^\d{10}$/.test(visitorPhoneNumber.trim());
      const isValid =
        visitorName.trim() !== "" &&
        visitorMessage.trim() !== "" &&
        isPhoneNumberValid;

      setIsFormValid(isValid);
    };

    validateForm();
  }, [visitorName, visitorPhoneNumber, visitorMessage]);

  const handleNameChange = (e) => {
    setVisitorName(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    const digits = value.replace(/\D/g, "");
    setVisitorPhoneNumber(digits);
  };

  const handleMessageChange = (e) => {
    const value = e.target.value;
    setVisitorMessage(value);
    setRemainingChars(500 - value.length);
  };

  const handleSubmit = async () => {
    if (isFormValid) {
      setLoading(true);
      try {
        await api.sendQuickReminder(publicKey, visitorName, visitorPhoneNumber, visitorMessage);
        resetVisitorDetails();
        showToast("Thanks, your note has been sent!");
        $("#quick-reminder").modal("hide");
      } catch (error) {
        console.error("Error sending quick reminder:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleReminderCancel = () => {
    resetVisitorDetails();
  };

  const resetVisitorDetails = () => {
    setVisitorName("");
    setVisitorPhoneNumber("");
    setVisitorMessage(defaultMessage);
    setIsFormValid(false);
    setRemainingChars(500 - defaultMessage.length); 
  };

  return (
    <div className="modal fade" id="quick-reminder" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog common-popup purchaser-details-popup">
        <div className="modal-content">
          <div className="modal-header">
            <p>SEND QUICK REMINDER</p>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleReminderCancel}></button>
          </div>
          <div className="modal-body login-box row">
            <div className="product-form mt-3">
              <form noValidate>
                <div className="user-box">
                  <div className="product-column">
                    <input
                      type="text"
                      name="yourName"
                      value={visitorName}
                      onChange={handleNameChange}
                      required
                    />
                    <label>Your Name</label>
                  </div>
                  <div className="product-column">
                    <input
                      type="text"
                      name="phoneNumber"
                      value={visitorPhoneNumber}
                      onChange={handlePhoneNumberChange}
                      maxLength={10}
                      required
                    />
                    <label>Your Phone Number</label>
                  </div>
                </div>

                <label className="mb-1">Your Message</label>
                <div className="user-box visitor-box mb-4">
                  <textarea
                    id="message"
                    name="Message"
                    placeholder="Enter Message here..."
                    value={visitorMessage}
                    onChange={handleMessageChange}
                    maxLength={500}
                  />
                  <div className="char-count">
                    {remainingChars} characters left
                  </div>
                </div>
              </form>
              <button
                type="button"
                className={`btn btn-primary form-btn edit-gift-btn guestbook-btn main-btn mx-auto my-2`}
                onClick={handleSubmit}
                disabled={!isFormValid || loading}
              >
                {loading ? "SENDING..." : "SEND"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendReminderModal;