import React from 'react';
import { Link } from 'react-router-dom';
import { showToast } from '../../utils/toastUtils';

const CashGiftSection = () => {

  const handleClick = () => {
    showToast('Something new is coming!');
  }

  return (
    <section className="welcome" data-aos="fade-up">
      <div className="hero-banner cash-gift">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-6"></div>
            <div className="col-lg-4 col-md-6 col-sm-6 float-end" data-aos="fade-left" data-aos-delay="100">
              <div className="hero-text my-5 py-5">
                <h1 className="my-4 heading-lmed">Embrace the Lifafa Legacy - "Graceful and traditional cash gifting."</h1>
                <p className="my-4">
                  Gracefully Gather Blessings for Your Bundle of Joy with a modern twist. Set up a <Link to="#">cash fund</Link> to nurture your
                  child's tomorrow - Contributions towards saving your baby’s sanctuary, education, or be it their cozy corner with future aspirations.
                </p>
                <div className="button">
                  <Link to="#" className="button-active" onClick={handleClick}>Create Cash Gift Fund</Link>
                </div>
                <div className="spacing"></div>
              </div>
            </div>
            <div className="col-lg-1 display-none"></div>
          </div>
        </div>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 670" style={{}} xmlSpace="preserve">
          <style type="text/css">
            {`.st0{fill:#FFFFFF;}`}
          </style>
          <path className="st0" d="M1031.7,642.23c-475.66,0-880.35-71.16-1030.58-170.5H0v200.15h1920V535.43 C1721.62,600.1,1397.67,642.23,1031.7,642.23z" />
        </svg>
      </div>
    </section>
  );
};

export default CashGiftSection;
