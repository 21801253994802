/* global $ */
import React, { useState, useEffect } from "react";
import api from "../../services/api";
import LoginModal from "../auth/LoginModal";
import Cookies from 'js-cookie';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { showToast } from "../../utils/toastUtils";
import { getRegistryCredentials } from '../../utils/registryUtils';
import { clearRegistryCredentials } from "../../utils/registryUtils";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const { accessToken, registryId, registryPublic, fromMobileApp, fromiOSDevice } = getRegistryCredentials();
  const [registryDetails, setRegistryDetails] = useState(null);
  const [registryPublicState, setRegistryPublicState] = useState(registryPublic);
  const isHomePage = () => location.pathname === "/";
  const isStaticPage =  ["/privacy-policy", "/terms&conditions", "/aboutus", "/advertise-with-us", "/feedback"].includes(location.pathname);
  const isDashboardPage = ["/dashboard", "/popular-gifts", "/baby-checklist", "/notifications", "/registry-settings", "/guestbook", "/visitor-setup", "/add-gifts", "/script-blocked"].includes(location.pathname);

  useEffect(() => {
    if (accessToken) {
      fetchRegistryDetails();
    }
    // eslint-disable-next-line
  }, [accessToken]);

  const fetchRegistryDetails = async () => {
    try {
      const response = await api.getDashboardDetails(accessToken, registryId);
      setRegistryDetails(response);
    } catch (error) {
      console.error("Error fetching registry details:", error.message);
    }
  };

  const getUserAnswerByIndex = (userAnswers, index) => {
    const answer = userAnswers[index]?.question_answer;
    return answer ? answer.split(' ')[0] : '';
  };

  useEffect(() => {
    if (registryPublic !== undefined) {
      setRegistryPublicState(registryPublic === 'true');
    } else {
      setRegistryPublicState(false);
    }

    const handleLogOutClick = () => {
      document.querySelector("ul.overlay").classList.toggle("active-overlay");
    };

    const handleOutsideClick = (event) => {
      const overlay = document.querySelector("ul.overlay");
      const logOutButton = document.querySelector("button.log-out");
      if (overlay && !overlay.contains(event.target) && logOutButton && !logOutButton.contains(event.target)) {
        overlay.classList.remove("active-overlay");
      }
    };

    const logOutButton = document.querySelector("button.log-out");
    if (logOutButton) {
      logOutButton.addEventListener("click", handleLogOutClick);
    }

    document.addEventListener("click", handleOutsideClick);

    return () => {
      if (logOutButton) {
        logOutButton.removeEventListener("click", handleLogOutClick);
      }
      document.removeEventListener("click", handleOutsideClick);
    };
    // eslint-disable-next-line
  }, []);

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleRegistrySearch = (e) => {
    if (e.key === 'Enter') {
      if (searchQuery.trim() !== "") {
        navigate(`/search?q=${searchQuery}`);
      } else {
        navigate("/search");
      }
    }
  };

  const handlePrivacyToggle = () => {
    $('#confirmationModal').modal('show');
  };

  const handleYes = async () => {
    try {  
      let updatedRegistryPublic;
  
      if (registryPublicState) {
        await api.makeRegistryPrivate(accessToken, registryId);
        updatedRegistryPublic = false;
      } else {
        await api.makeRegistryPublic(accessToken, registryId);
        updatedRegistryPublic = true;
      }

      Cookies.set('registryPublic', updatedRegistryPublic, { expires: 30, sameSite: 'None', secure: true });
      localStorage.setItem('registryPublic', updatedRegistryPublic);
      setRegistryPublicState(updatedRegistryPublic);
      const toastIcon = registryPublicState ? <i className="la la-lock i-icon"></i> : <i className="la la-unlock i-icon"></i>;
      const toastMessage = registryPublicState ? 'Your Registry is now Private' : 'Your Registry is now Public';
      showToast(<>{toastIcon}&nbsp;{toastMessage}</>);
  
      $('#confirmationModal').modal('hide');
    } catch (error) {
      console.error('Error changing privacy settings:', error.message);
      showToast('Failed to change privacy settings', 'error');
    }
  };
  
  const handleNo = () => {
    $('#confirmationModal').modal('hide');
  };

  const handleLogout = async () => {
    try {
      await api.logout(accessToken);
      GoogleAuth.signOut();
      clearRegistryCredentials();
      window.location.pathname = "/";
    } catch (error) {
      clearRegistryCredentials();
      window.location.pathname = "/";
      console.error("Logout error:", error.message);
    }
  };

  return (
    <>
      {fromiOSDevice && <div className="ios-header"></div>}
      <div className="top-header py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 p-0 div-web">
              <Link className="navbar-logo" to="/">
                <img src="/assets/images/logo.png" alt="logo" loading="lazy" />
              </Link>
            </div>
            <div className="col-lg-9 nav-center">
              <div className="d-flex justify-content-end align-items-center">
                {(isHomePage() || isStaticPage ) && (
                  <>
                    <div className={`search-reg ${accessToken ? 'me-4' : 'me-2'}`}>
                      <div className="search-registry find-reg-header d-none d-md-block">
                        <input
                          type="text"
                          placeholder="Find a Registry or Gift List"
                          value={searchQuery}
                          onChange={handleInputChange}
                          onKeyPress={handleRegistrySearch}
                        />
                        <div className="search-icon-header">
                        <Link to={searchQuery.trim() !== "" ? `/search?q=${searchQuery}` : "/search"}>
                          <i className="la la-search reversed-icon"></i>
                        </Link>
                        </div>
                      </div>
                      {!accessToken && (
                        <div className="d-block d-md-none">
                          <Link to="/search"><i className="la la-search"></i> Find A Registry? </Link>
                        </div>
                      )}
                    </div>
                  </>
                  )}
                  {accessToken ? (
                    <div className="right-box right-box-overlay float-end after-login">
                      <div className="visitor-view my-2">
                      {isDashboardPage ? (
                        <>
                          <p>
                            <i className={registryPublicState ? "la la-unlock" : "la la-lock"}></i>&nbsp;
                            Your Registry is completely {registryPublicState ? "Public" : "Private"}.&nbsp;
                            <br className="d-sm-none" />
                            <span className="toggle-privacy">
                              <Link to="#" onClick={handlePrivacyToggle}>
                                Make it {registryPublicState ? "private" : "public"}.
                              </Link>
                            </span>
                          </p>
                        </>
                      ) : (
                        !isDashboardPage && registryDetails && (
                          <p className="fs-6">
                            Hi, {registryDetails?.user_first_name}&nbsp;{getUserAnswerByIndex(registryDetails?.user_answers ?? [], 1) && 
                              `& ${getUserAnswerByIndex(registryDetails?.user_answers ?? [], 1)}`}!
                          </p>
                        )
                      )}
                      </div>
                      <div className="login-info div-web">
                        <Link to="/notifications">
                          <i className="la la-bell"></i>
                        </Link>
                        <button type="button" className="log-out log-out-header">
                          <i className="la la-user-circle"></i>
                        </button>
                        <ul className="overlay">
                          {isDashboardPage && (
                            <>
                              <li>
                                <Link to="/onboarding-process">Create New Registry</Link>
                              </li>
                              <li>
                                <Link to="/all-registry">Manage Registries</Link>
                              </li>
                            </>
                          )}
                          {!isDashboardPage && (
                          <li>
                            <Link to="/dashboard">My Gifts</Link>
                          </li>
                          )}
                          <li className="logout">
                            <Link to="#" onClick={handleLogout}><i className="la la-sign-out-alt fs-6 me-1"></i>Log Out</Link>
                          </li>
                        </ul>
                    </div>
                  </div>
                  ) : (
                    <div className="right-box float-end my-1">
                      <div className="login">
                        {fromMobileApp ? (
                          <Link to="/m-login">Login</Link>
                        ) : (
                          <button type="button" data-bs-toggle="modal" data-bs-target="#Login">
                            Login
                          </button>
                        )}
                        <Link to="/signup"> SignUp </Link>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>

          <div className="modal fade" id="confirmationModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body row">
                  <div className="col-lg-12">
                    <div className="confirmation-visitor-modal mt-4 text-center">
                      <h5 className="mb-4">Change privacy setting</h5>
                      <p>Do you really want to change your privacy settings?</p>
                    </div>
                    <div className="user-detials-btn text-center mt-4 mb-4">
                      <button type="button" className="common-modal-btn common-btn" onClick={handleNo}>NO</button>
                      <button type="button" className="common-modal-btn common-btn active-btn" onClick={handleYes}>YES</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoginModal />
      </div>
    </>
  );
};

export default Header;