/* global $ */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { showToast } from "../../utils/toastUtils";
import Header from "../../components/common/Header";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import api from "../../services/api";
import { getRegistryCredentials } from "../../utils/registryUtils";

const GuestbookPage = () => {
  const [guestbookDetails, setGuestbookDetails] = useState([]);
  const [guestbookVisibility, setGuestbookVisibility] = useState(true);
  const [guestbookIdToDelete, setGuestbookIdToDelete] = useState([]);
  const { accessToken, registryId } = getRegistryCredentials();
  const [loading, setLoading] = useState(true);

  const fetchGuestbookData = async () => {
    try {
      const response = await api.getGuestbookDetails(accessToken, registryId);
      setGuestbookDetails(response.data);
      setGuestbookVisibility(response.guestbook_visible);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching guestbook details:", error);
      setLoading(true);
    }
  };

  useEffect(() => {
    fetchGuestbookData();
    // eslint-disable-next-line
  }, []);

  const formatDateAndTime = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);

    const optionsDate = {
      weekday: "long",
      month: "long",
      day: "numeric",
    };

    const optionsTime = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const dateStringFormatted = date.toLocaleDateString("en-US", optionsDate);
    const timeStringFormatted = date.toLocaleTimeString("en-US", optionsTime);
    const year = date.getFullYear();

    return (
      <div>
        <span>{dateStringFormatted}</span>
        <br />
        <span>{year}</span>
        <br />
        <span>{timeStringFormatted}</span>
      </div>
    );
  };

  const handleOpenDeleteModal = (GuestbookId) => {
    setGuestbookIdToDelete(GuestbookId);
    $("#delete-modal").modal("show");
  };

  const handleConfirmDelete = async () => {
    try {
      await api.deleteGuestbookEntry(accessToken, guestbookIdToDelete);

      showToast("Guest has been deleted from guestbook", "success");
      fetchGuestbookData();
      $("#delete-modal").modal("hide");
    } catch (error) {
      console.error("Error deleting guest from guestbook:", error.message);
      showToast("Failed to delete guest from guestbook", "error");
    } finally {
      setGuestbookIdToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    $("#delete-modal").modal("hide");
  };

  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          <Navbar />
        </header>
        {loading ? (
          <div className="custom-loader-container main-loader fs-5">
            <div className="spinner-border custom-loader" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <main className="main main-dashboard">
            <article>
              <section>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="dashboard">
                        <div className="user-dashboard-page">
                          <div className="guestbook-heading mb-3">
                            <h3>Guestbook</h3>
                          </div>
                          <div className="guestbook-status">
                            <p>
                              Your guestbook is currently turned{" "}
                              {guestbookVisibility ? "on" : "off"}.
                            </p>
                          </div>
                          <div className="guestbook-info mb-4">
                            <p>
                              {guestbookVisibility && (
                                <>
                                  Visitors to your registry will have the option to sign it when they view your gift list.
                                </>
                              )}
                              &nbsp;You can change this setting
                              <Link
                                to="/visitor-setup"
                                className="change-color"
                                onClick={() => window.scrollTo(0, 0)}
                              > here
                              </Link>.
                            </p>
                            <p>
                              {guestbookDetails.length || 0} guests have signed the guestbook on your Baby Registry.
                              {guestbookDetails.length > 0 && (
                                <>This list can only be viewed by you.</>
                              )}
                            </p>
                          </div>
                        </div>
                        {guestbookDetails.length > 0 && (
                          <div className="table-responsive">
                            <table className="table custom-guestbook-table">
                              <thead className="custom-thead">
                                <tr>
                                  <th className="text-center">DATE</th>
                                  <th className="text-center">GUEST DETAILS</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {guestbookDetails
                                  .slice(0)
                                  .reverse()
                                  .map((guestbook, index) => (
                                    <tr key={index}>
                                      <td className="guestbook-first-column">
                                        {formatDateAndTime(
                                          guestbook.created_at
                                        )}
                                      </td>
                                      <td>
                                        <>
                                          <span>{guestbook.visitor_name}</span>
                                          <br />
                                          <span>{guestbook.visitor_email}</span>
                                          <br />
                                          <span className="guestbook-msg d-flex mt-1 mb-2">Message: {guestbook.visitor_comments}</span>
                                        </>
                                      </td>
                                      <td className="guestbook-third-column">
                                        <Link
                                          to="#"
                                          onClick={() =>
                                            handleOpenDeleteModal(guestbook.id)
                                          }
                                        >
                                          <i className="la la-trash fs-5"></i>
                                        </Link>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        )}

                        <div className="modal fade" id="delete-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-body row">
                                <div className="col-lg-12">
                                  <div className="confirmation-modal text-center mt-4">
                                    <h5 className="mb-4">Delete Confirmation</h5>
                                    <p> Are you sure you want to delete this guest from your guestbook?</p>
                                  </div>
                                  <div className="user-detials-btn text-center mt-4 mb-4">
                                    <button type="button" className="common-modal-btn common-btn" onClick={handleCancelDelete}>NO</button>
                                    <button type="button" className="common-modal-btn common-btn active-btn" onClick={handleConfirmDelete}>YES</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </article>
          </main>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default GuestbookPage;
