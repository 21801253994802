import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

const WelcomeSection = () => {
  const isPortrait = useMediaQuery('(max-width:767px)');

  return (
    <main className="main">
      <article> 
        <section className="welcome">
          <div className="hero-banner">
            <div>
              <div className="row">
                {isPortrait ? (
                  <>
                    <div className="col-lg-6">
                      <div className="hero-text mt-5 py-5">
                        <h1 className="my-5">Welcome Little Wonders with Love</h1>
                        <h2 className="hero-text-info">The Essential Baby Registry for Every Joyful Moment – Bumps, Babies & Beyond</h2>
                        <div className="button">
                          <Link to="/signup"  className="button-active" onClick={() => window.scrollTo(0, 0)}> Start a Baby Registry </Link>
                          <Link to="/search"  className="button-outline" onClick={() => window.scrollTo(0, 0)}> Find a Registry </Link>
                        </div>
                      </div>
                      <div className="hero-img">
                        <img src="/assets/images/family.png" alt="" />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-lg-6 col-md-5 col-sm-6">
                      <div className="hero-img">
                        <img src="assets/images/family.png" alt="" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-7 col-sm-6 float-end" data-aos="fade-up" data-aos-delay="100">
                      <div className="hero-text my-5 py-5">
                        <h1 className="my-5">Welcome Little Wonders with Love</h1>
                        <h2 className="hero-text-info">The Essential Baby Registry for Every Joyful Moment – Bumps, Babies & Beyond</h2>
                        <div className="button">
                          <Link to="/signup"  className="button-active" onClick={() => window.scrollTo(0, 0)}> Start a Baby Registry </Link>
                          <Link to="/search"  className="button-outline registry-btn" onClick={() => window.scrollTo(0, 0)}> Find a Registry </Link>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 670" style={{}} xmlSpace="preserve">
              <style type="text/css">
                {`.st0{fill:#FFFFFF;}`}
              </style>
              <path className="st0" d="M1031.7,642.23c-475.66,0-880.35-71.16-1030.58-170.5H0v200.15h1920V535.43 C1721.62,600.1,1397.67,642.23,1031.7,642.23z" />
            </svg>
          </div>
        </section>
      </article> 
    </main>
  );
};

export default WelcomeSection;