import React, { useState } from "react";
import { Link } from "react-router-dom";
import { showToast } from "../../../utils/toastUtils";
import api from '../../../services/api';
import { getRegistryCredentials } from "../../../utils/registryUtils";

const ChangePasswordModal = () => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const { accessToken, userId } = getRegistryCredentials();
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [validation, setValidation] = useState({
    currentPassword: true,
    newPassword: true,
    confirmNewPassword: true,
  });

  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleChange = (field, value) => {
    setValidation((prevValidation) => ({ ...prevValidation, [field]: true }));
    setPasswordData((prevData) => ({ ...prevData, [field]: value }));
  };
  
  const handleChangePassword = async (e) => {
    e.preventDefault();

    const updatedRequiredValidation = {
      currentPassword: !!passwordData.currentPassword.trim(),
      newPassword: !!passwordData.newPassword.trim(),
      confirmNewPassword: !!passwordData.confirmNewPassword.trim(),
    };
  
    const updatedNewPasswordValidation = {
      currentPassword: !!passwordData.currentPassword.trim(),
      newPassword: passwordData.newPassword.length >= 8 && passwordData.newPassword.length <= 20,
      confirmNewPassword: !!passwordData.confirmNewPassword.trim(),
    };
  
    const updatedMismatchValidation = {
      currentPassword: !!passwordData.currentPassword.trim(),
      newPassword: passwordData.newPassword.length >= 8 && passwordData.newPassword.length <= 20,
      confirmNewPassword: passwordData.newPassword === passwordData.confirmNewPassword,
    };
  
    if (!updatedRequiredValidation.currentPassword || !updatedRequiredValidation.newPassword || !updatedRequiredValidation.confirmNewPassword) {
      setValidation(updatedRequiredValidation);
      showToast('Please fill in all required fields');
      return;
    }
  
    if (!updatedNewPasswordValidation.newPassword) {
      setValidation(updatedNewPasswordValidation);
      showToast('Please provide a password between 8-20 characters');
      return;
    }
  
    if (!updatedMismatchValidation.confirmNewPassword) {
      setValidation(updatedMismatchValidation);
      showToast("Passwords doesn't match");
      return;
    }

    try {
      const changePassword = {
        current_password: passwordData.currentPassword,
        new_password: passwordData.newPassword,
        confirm_password: passwordData.confirmNewPassword,
      };
  
      const response = await api.changePassword(accessToken, userId, changePassword);

      if (response) {
        showToast('Your Password has been changed successfully',"success");
        setPasswordData({ currentPassword: "", newPassword: "", confirmNewPassword: "" });
        setValidation({ currentPassword: true, newPassword: true, confirmNewPassword: true });
        document.getElementById("change-password").click();
      }
    } catch (error) {
      console.log(error.message);
      if (error.message === "Current password is incorrect") {
        showToast('Current password is incorrect');
      } else if (error.message === "The new password field and current password must be different.") {
        showToast('New password and current password must be different');
      } else {
        console.error("Error updating password:", error.message);
        showToast('Failed to update password', "error");
      }
    }
  };

  const handleCancel = () => {
    setPasswordData({ currentPassword: "", newPassword: "", confirmNewPassword: "" });
    setValidation({ currentPassword: true, newPassword: true, confirmNewPassword: true });
  };
 
  return (
    <div className="modal fade" id="change-password" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog add-gifts-popup">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-logo">
              <h2>Change Your Password</h2>
            </div>
            <div className="modal-user">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCancel}></button>
            </div>
          </div>
          <div className="modal-body login-box row">
            <div className="user-settings edit-mode-editable-full">
              <div className="user-box mt-3">
                <input
                  type={showCurrentPassword ? "text" : "password"}
                  name="Current Password"
                  value={passwordData.currentPassword}
                  onChange={(e) => handleChange("currentPassword", e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && handleChangePassword(e)}
                  className={`form-control ${!validation.currentPassword ? "is-invalid" : ""}`}
                  maxLength={20}
                  required
                />
                <label>Current Password</label>
                <Link
                  className="password-toggle-icon password-icon position-absolute end-0 top-50 me-3"
                  onClick={toggleCurrentPasswordVisibility}
                >
                  {showCurrentPassword ? (
                    <i className="las la-eye-slash fs-4"></i>
                  ) : (
                    <i className="las la-eye fs-4"></i>
                  )}
                </Link>
              </div>

              <div className="user-box">
                <input
                  type={showNewPassword ? "text" : "password"}
                  name="New Password" 
                  value={passwordData.newPassword}
                  onChange={(e) => handleChange("newPassword", e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && handleChangePassword(e)}
                  className={`no-margin form-control ${!validation.newPassword ? "is-invalid" : ""}`}
                  maxLength={20}
                  required
                />
                <label>New Password</label>
                <Link
                  className="password-toggle-icon position-absolute end-0 bottom-50 me-3"
                  onClick={toggleNewPasswordVisibility}
                >
                  {showNewPassword ? (
                    <i className="las la-eye-slash fs-4"></i>
                  ) : (
                    <i className="las la-eye fs-4"></i>
                  )}
                </Link>
                <p className="password-char">8-20 characters</p>
              </div>

              <div className="user-box mt-2">
                <input
                type="password" name="Confirm New Password"
                value={passwordData.confirmNewPassword}
                onChange={(e) => handleChange("confirmNewPassword", e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleChangePassword(e)}
                className={`no-margin form-control ${!validation.confirmNewPassword ? "is-invalid" : ""}`}
                maxLength={20} 
                required
                />
                <label>Confirm New Password</label>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="user-detials-btn d-flex mb-2">
              <button onClick={handleCancel} data-bs-dismiss="modal" className="settings-btn">
                Cancel
              </button>
              <button onClick={handleChangePassword} className="settings-btn active-btn">
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordModal;