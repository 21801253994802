import React, { useState } from "react";
import { Link } from "react-router-dom";

const FaqSection = () => {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
    if (showAll) {
      document.getElementById("faq-section").scrollIntoView();
    }
  };

  return (
    <main className="main">
      <article>
        <section id="faqs" className="faq py-5" data-aos="fade-up">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="store-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <h1 className="my-4 heading-med">
                    Frequently Asked Questions
                  </h1>
                  <p>
                    Want to know more about creating a Universal Gift Registry?
                    Start here.
                  </p>
                  <div className="spacing-mid"></div>
                </div>
              </div>
              {(showAll ? faqs : faqs.slice(0, 4)).map((faq, index) => (
                <FaqItem key={index} question={faq.question} answer={faq.answer} />
              ))}

              <div className="col-lg-12 col-md-12" data-aos="fade-right" data-aos-delay="100">
                <div className="button mt-5">
                  <Link onClick={toggleShowAll} className="button-active">
                    {showAll ? "Show Less FAQs" : "Read All FAQs"}
                  </Link>
                </div>
                <div className="spacing"></div>
                <div className="spacing-small"></div>
              </div>
            </div>
          </div>

          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1920 670"
            style={{}}
            xmlSpace="preserve"
          >
            <style type="text/css">{`.st0 { fill: #FFFFFF; }`}</style>
            <path
              className="st0"
              d="M1031.7,642.23c-475.66,0-880.35-71.16-1030.58-170.5H0v200.15h1920V535.43
                 C1721.62,600.1,1397.67,642.23,1031.7,642.23z"
            />
          </svg>
        </section>
      </article>
    </main>
  );
};

const FaqItem = ({ question, answer }) => {
  return (
    <div className="col-md-12">
      <div className="faq-inner">
        <div className="row">
          <div className="col-md-3" data-aos="fade-right">
            <div className="question">
              <h4>{question}</h4>
            </div>
          </div>

          <div className="col-md-1"></div>

          <div className="col-md-8" data-aos="fade-left">
            <div className="answer mb-5">
              <p>{answer}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const faqs = [
  { question: "What exactly is a Universal Quick Registry?", answer: "A Universal Quick Registry allows you to create a list of desired gifts from any online store around the globe. It's a single, convenient list for all sorts of gifts—products, experiences, and even Lifafa for cash funds for the big-ticket items. We believe in celebrating your every occasion without limits!" },
  { question: "How can I set up my own Universal Quick Registry?", answer: 'It’s easy! Just click on "Start a Registry" on our site and follow the steps. Once you’ve created your baby registry, you can manage it from either the QuickRegistry website or our app.' },
  { question: "Do I have to pay for a baby registry?", answer: "You do not! Creating a registry on Quick Registry is absolutely free." },
  { question: "When should I start creating a registry?", answer: "The earlier, the better. Friends and family will start thinking about the perfect gift as soon as they hear about your celebration, so add lots of options, at lots of price points, as soon as you can." },
  { question: "How can my friends and family access my registry?", answer: "Absolutely! Your loved ones can search for your registry by just entering at least two characters of the name or a unique shareable link. We've made discovery effortless so that no one misses out on the joy of giving." },
  { question: "Will gift-givers know what items have already been purchased?", answer: "Yes, Once a gift is purchased, your friends and family can mark it as gifted, ensuring you receive only the gifts you actually need. Preventing duplicate gifts and ensuring you receive what you need. It's transparency and convenience wrapped into one." },
  { question: "What if I need to update or change registry details?", answer: "Your registry is ﬂexible. You are the boss of your registry and it is ﬂexible. You can add/remove any item, edit your list or details and adjust your cash fund amount whenever you like. With Quick Registry, your gift list evolves as your journey does." },
  { question: "How does QuickRegistry make gift-giving more meaningful?", answer: "By aligning gifts with what an organiser truly needs, QuickRegistry makes every contribution special. From the practical to the desirable, it's about meaningful gifting that celebrates life." }
];

export default FaqSection;