/* global $ */
import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { showToast } from "../../../utils/toastUtils";
import { getRegistryCredentials } from '../../../utils/registryUtils';
import api from "../../../services/api";

const EditCategoriesModal = ({registryDetails, fetchRegistryDetails}) => {
  const [mode, setMode] = useState("create");
  const [isLoading, setIsLoading] = useState(false); 
  const [newCategoryName, setNewCategoryName] = useState("");
  const [isCategoryValid, setIsCategoryValid] = useState(true);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);
  const { accessToken, registryId } = getRegistryCredentials();
  const inputRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (mode === "create") {
        handleCreateCategory();
      } else {
        handleEditCategory();
      }
    }
  };

  const handleCreateCategory = async () => {
    if (!newCategoryName.trim()) {
      showToast("Please enter a category title");
      setIsCategoryValid(false);
      return;
    }

  const trimmedNewCategory = newCategoryName.trim();
    if (registryDetails && registryDetails.item_categories.some(
      category => category.name.toUpperCase() === trimmedNewCategory.toUpperCase())) {
      showToast("Category already exists. Please enter a new title.");
      setIsCategoryValid(false);
      return;
    }

    setIsLoading(true); 
    try {
      await api.createCategory(accessToken, registryId, newCategoryName);
      showToast("Category created successfully", "success");
      setNewCategoryName("");
      fetchRegistryDetails();
    } catch (error) {
      console.error("Error creating category:", error);
      showToast("Failed to create category", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditSetCategory = (category) => {
    setMode("edit");
    setIsCategoryValid(true);
    setSelectedCategoryId(category.id);
    setNewCategoryName(category.name);
    inputRef.current.focus();
  };

  const handleEditCategory = async () => {
    if (!newCategoryName.trim()) {
      showToast("Please enter a category title");
      setIsCategoryValid(false);
      return;
    }

  const editedCategory = registryDetails.item_categories.find(category => category.id === selectedCategoryId);
    if (editedCategory && editedCategory.name.toUpperCase() === newCategoryName.toUpperCase()) {
      setIsCategoryValid(true);
    } else {
      const trimmedNewCategory = newCategoryName.trim();
      if (registryDetails.item_categories.some(category => category.name.toUpperCase() === trimmedNewCategory.toUpperCase())) {
        showToast("Category already exists. Please enter a new title.");
        setIsCategoryValid(false);
        return;
      }
    }

    setIsLoading(true);
    try {
      await api.updateCategory(accessToken, registryId, selectedCategoryId, newCategoryName);
      showToast("Category updated successfully", "success");
      setMode("create");
      setNewCategoryName("");
      fetchRegistryDetails();
      setSelectedCategoryId(null);
    } catch (error) {
      console.error("Error updating category:", error);
      showToast("Failed to update category", "error");
    }  finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setNewCategoryName("");
    setMode("create");
    setIsCategoryValid(true);
    setSelectedCategoryId(null);
  }

  const handleOpenDeleteModal = (category) => {
    setMode("create");
    setNewCategoryName("");
    setSelectedCategoryId(category.id);
    setSelectedCategoryName(category.name);
    $("#edit-categories").modal("hide");
    $("#delete-category").modal("show");
  }

  const handleConfirmDelete = async () => {
    try {
      await api.deleteCategory(accessToken, selectedCategoryId);
      showToast('Your category has been deleted', "success");
      $("#delete-category").modal("hide");
      setTimeout(() => {
        $("#edit-categories").modal("show");
      }, 300)
      fetchRegistryDetails();
      setSelectedCategoryId(null);
    } catch (error) {
      console.error("Error deleting category:", error.message);
      showToast('Failed to delete category', "error");
    }
  }

  const handleCancelDelete = () => {
    $("#delete-category").modal("hide");
    $("#edit-categories").modal("show");
  }

  return (
    <>
      <div className="modal fade" id="edit-categories" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog common-popup purchaser-details-popup">
          <div className="modal-content">
            <div className="modal-header">
              <p>EDIT CATEGORIES</p>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCancel}></button>
            </div>
            <div className="modal-body">
              <div className="category-main-container">
                <div className="category-container">
                  <p className="category-heading me-3">{mode === "create" ? "Create new category" : "Edit category"}</p>
                  <form noValidate>
                    <div className="user-box category-input">
                      <input
                        type="text"
                        name="categoryName"
                        value={newCategoryName}
                        onChange={(e) => {
                          setNewCategoryName(e.target.value);
                          setIsCategoryValid(true);
                        }}
                        onKeyDown={handleKeyDown}
                        className={`form-control ${!isCategoryValid ? "is-invalid" : ""}`}
                        autoComplete="off"
                        maxLength="20"
                        ref={inputRef}
                        required
                      />
                    </div>
                  </form>
                  <div className="user-detials-btn category-btn-box">
                    {mode === "create" ? (
                      <button type="button" className="btn common-modal-btn category-btn active-btn mt-2" onClick={handleCreateCategory} disabled={isLoading}>CREATE</button>
                    ) : (
                      <>
                        <button type="button" className="btn common-modal-btn category-btn active-btn" onClick={handleEditCategory} disabled={isLoading}>SAVE</button>
                        <button type="button" className="common-modal-btn category-btn mt-2" onClick={handleCancel}>CANCEL</button>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table custom-table">
                  <thead className="custom-thead">
                    <tr>
                      <th className="align-middle">CATEGORY TITLE</th>
                      <th className="text-center">TOTAL GIFTS</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                  {registryDetails &&
                    registryDetails.item_categories
                      .filter(category => category.name !== "All Gifts")
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((category, index) => (
                        <tr key={index}>
                          <td className="align-middle">{category.name}</td>
                          <td className="category-gift-column align-middle text-center">{category.items_count || 0}</td>
                          <td className="align-middle text-end">
                            {category.name !== "Private" && (
                              <Link to="#" className="change-color" onClick={() => handleEditSetCategory(category)}>
                              <i className="la la-pen fs-5"></i>
                              </Link>
                            )}
                          </td>
                          <td className="align-middle">
                          {category.name !== "Private" && (
                              <Link to="#" className="change-color" onClick={() => handleOpenDeleteModal(category)}>
                              <i className="la la-trash fs-5"></i>
                              </Link>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="delete-category" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body row">
              <div className="col-lg-12">
                <div className="confirmation-modal text-center mt-4">
                  <h5 className="mb-4">Delete a Category</h5>
                  <p>Are you sure want to delete <strong>{selectedCategoryName}</strong> category?</p>
                </div>
                <div className="user-detials-btn text-center mt-4 mb-4">
                  <button type="button" className="common-modal-btn common-btn" onClick={handleCancelDelete}>NO</button>
                  <button type="button" className="common-modal-btn common-btn active-btn" onClick={handleConfirmDelete}>YES</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCategoriesModal;
