/* global $ */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Navbar from '../../components/common/Navbar';
import BackButton from '../../components/common/BackButton';
import { showToast } from '../../utils/toastUtils';
import { shopImages, coverImages } from '../../utils/imagesUtils';
import ViewOnlineModal from '../../components/dashboard/modal/ViewOnlineModal';
import AddShopGiftModal from '../../components/dashboard/modal/AddShopGiftModal';
import api from '../../services/api';

const ProductPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const subcategory = queryParams.get('subcategory') || '';
  const allShopImages = shopImages();
  const { category } = useParams();
  const [sortBy, setSortBy] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [products, setProducts] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedAges, setSelectedAges] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedPriceRanges, setSelectedPriceRanges] = useState([]);
  const [selectedWishlists, setSelectedWishlists] = useState([]);
  const [isSearchTriggered, setIsSearchTriggered] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const hasMounted = useRef(false);
  const observer = useRef();

  const formattedCategory = (subcategory || category).toLowerCase().replace(/ /g, '-');
  const image = allShopImages.find(img => img.alt.toLowerCase().replace(/ /g, '-').includes(formattedCategory));
  const coverImage = coverImages.find(cover => cover.alt.toLowerCase().replace(/ /g, '-').includes(formattedCategory));

  const categories = [
    { value: "all", label: "All Categories" },
    { value: "activity", label: "Activity" },
    { value: "bath", label: "Bath" },
    { value: "bedding", label: "Bedding" },
    { value: "care", label: "Care" },
    { value: "clothing", label: "Clothing" },
    { value: "diapering", label: "Diapering" },
    { value: "feeding", label: "Feeding" },
    { value: "health", label: "Health" },
    { value: "maternity", label: "Maternity" },
    { value: "toys", label: "Toys" }
  ];
  
  const ages = [
    { value: "0-to-6-months", label: "0 to 6 Months" },
    { value: "6-months-to-1-year", label: "6 Months to 1 Year" },
    { value: "1-to-2-years", label: "1 to 2 Years" },
    { value: "2-to-3-years", label: "2 to 3 Years" },
    { value: "3-to-5-years", label: "3 to 5 Years" },
    { value: "5-to-7-years", label: "5 to 7 Years" },
    { value: "7-to-10-years", label: "7 to 10 Years" },
    { value: "10-years-and-over", label: "10 Years and Over" }
  ];
  
  const priceRanges = [
    { name: "price-under-500", label: "Gifts under ₹500" },
    { name: "price-under-1000", label: "Gifts under ₹1000" },
    { name: "price-under-2000", label: "Gifts under ₹2000" },
    { name: "price-under-3000", label: "Gifts under ₹3000" },
    { name: "price-under-4000", label: "Gifts under ₹4000" },
    { name: "price-under-5000", label: "Gifts under ₹5000" },
    { name: "price-over-5000", label: "Gifts over ₹5000" },
  ];

  const exampleWishlists = [
    { name: "adding-to-the-brood", label: "Adding to the Brood" },
    { name: "books", label: "Books" },
    { name: "christening", label: "Christening" },
    { name: "gadget-lovers", label: "Gadget Lovers" },
    { name: "gifts-for-1-year-old", label: "Gifts for a 1 Year Old" },
    { name: "gifts-for-2-year-old", label: "Gifts for a 2 Year Old" },
    { name: "gifts-for-3-year-old", label: "Gifts for a 3 Year Old" },
    { name: "gifts-for-4-year-old", label: "Gifts for a 4 Year Old" },
    { name: "gifts-for-5-year-old", label: "Gifts for a 5 Year Old" },
    { name: "gifts-for-a-baby", label: "Gifts for a Baby" },
    { name: "hospital-bag", label: "Hospital Bag" },
    { name: "personalised", label: "Personalised" },
    { name: "small-businesses", label: "Small Businesses" },
    { name: "something-for-mum", label: "Something for Mum" },
    { name: "something-special", label: "Something Special" },
    { name: "subscriptions", label: "Subscriptions" },
    { name: "timeless-baby-items", label: "Timeless Baby Items" },
  ];

  const filteredCategories = category === "r-for-rabbit"
  ? categories.filter((cat) => cat.value !== "clothing" && cat.value !== "health")
  : categories;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (hasMounted.current) {
      if (!isSearchTriggered) {
        if (searchQuery.trim() === "") {
          fetchProducts();
        } else {
          fetchProducts();
        }
      }
    } else {
      hasMounted.current = true;
    }
  
    setIsSearchTriggered(false);
    // eslint-disable-next-line
  }, [currentPage, sortBy, selectedCategories, selectedPriceRanges, selectedWishlists, selectedAges, searchQuery, subcategory]);

  useEffect(() => {
    const selectPreFilters = () => {
      if (subcategory) {
        const matchingCategory = categories.find(cat => cat.value === subcategory);
        if (matchingCategory) {
          setSelectedCategories([matchingCategory.value]);
        }
  
        const matchingAge = ages.find(age => age.value === subcategory);
        if (matchingAge) {
          setSelectedAges([matchingAge.value]);
        }
  
        const matchingWishlist = exampleWishlists.find(wishlist => wishlist.name === subcategory);
        if (matchingWishlist) {
          setSelectedWishlists([matchingWishlist.name]);
        }
      }
  
      setSelectedCategories(prevCategories => (prevCategories.length === 0 ? ["all"] : prevCategories));
    };
  
    selectPreFilters();
    // eslint-disable-next-line
  }, [subcategory]);

  const fetchProducts = async () => {
    if (currentPage === 1) {
      setLoading(true);
    } else {
      setLoadingMore(true);
    }

    try {
      const categoriesToFetch = selectedCategories.length > 0 && selectedCategories[0] !== "all" ? selectedCategories : [];
      const wishlistsToFetch = selectedWishlists.length > 0 ? selectedWishlists : [];
      const agesToFetch = selectedAges.length > 0 ? selectedAges : [];
      
      const subcategoryToFetch = [...categoriesToFetch, ...wishlistsToFetch, ...agesToFetch].join(',') || undefined;
  
      let maxPrice = undefined;
      let minPrice = undefined;
  
      if (selectedPriceRanges.length > 0) {
        const priceRange = selectedPriceRanges[0];
        if (priceRange.startsWith("price-under")) {
          maxPrice = parseInt(priceRange.split('-')[2], 10);
        } else if (priceRange === "price-over-5000") {
          minPrice = 5000;
        }
      }
  
      const response = await api.getShopProducts(category, subcategoryToFetch, searchQuery, sortBy, maxPrice, minPrice, currentPage);
  
      if ((response.data && response.data.length === 0) || 
          response.message === "No data found" || 
          response.message === "No results found") {
        setNoResults(true);
        setProducts([]);
        setHasMore(false);
      } else {
        setNoResults(false);
        if (currentPage === 1) {
          setProducts(response.data);
        } else {
          setProducts((prevProducts) => [...prevProducts, ...response.data]);
        }
        if (response.data.length < 20) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    setCurrentPage(1);
  
    if (value.trim() === "") {
      setIsSearchTriggered(false);
      fetchProducts();
    } else {
      setIsSearchTriggered(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (searchQuery.trim() !== "") {
        fetchProducts();
      } else {
        showToast("Please type something to search");
      }
    }
  };

  const handleSearchIconClick = () => {
    if (searchQuery.trim() !== "") {
      fetchProducts();
    } else {
      showToast("Please enter a search term");
    }
  };

  const handleSortChange = (event) => {
    const selectedSort = event.target.value;
    setSortBy(selectedSort === "" ? "" : selectedSort);
    setCurrentPage(1);
  };

  const handleAddToWishlist = (productId) => {
    setSelectedProductId(productId);
    $("#ShopGift").modal("show");
  };

  const handleViewOnline = (product) => {
    setSelectedProduct(product);
    $("#view-online-modal").modal("show");
  }

  const handleCategoryChange = (category) => {
    setSearchQuery("");
    setCurrentPage(1);
    setSelectedCategories((prev) => {
      if (category === "all") {
        return ["all"];
      }
  
      if (prev.includes("all")) {
        return [category];
      }
  
      const isSelected = prev.includes(category);
        if (isSelected) {
          const newCategories = prev.filter((cat) => cat !== category);
          return newCategories.length === 0 ? ["all"] : newCategories;
      } else {
          return [...prev, category];
      }
    });
    setProducts([]);
  };

  const handleAgeChange = (age) => {
    setSearchQuery("");
    setCurrentPage(1);
    setSelectedAges((prev) =>
      prev.includes(age) ? prev.filter((a) => a !== age) : [...prev, age]
    );
    setProducts([]);
  };
  
  const handlePriceRangeChange = (rangeName) => {
    setSearchQuery("");
    setCurrentPage(1);  
    setSelectedPriceRanges((prev) => 
      prev.includes(rangeName) ? [] : [rangeName]
    );
    setProducts([]);
  };
  
  const handleWishlistChange = (wishlistName) => {
    setSearchQuery("");
    setCurrentPage(1);
    setSelectedWishlists((prev) =>
      prev.includes(wishlistName) ? prev.filter((wishlist) => wishlist !== wishlistName) : [...prev, wishlistName]
    );
    setProducts([]);
  };

  const lastProductElementRef = useCallback(node => {
    if (loadingMore) return; 
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setCurrentPage(prevPage => prevPage + 1);
      }
    });

    if (node) observer.current.observe(node);
  }, [loadingMore, hasMore]);

  const filterCategory = (
    <>
      <div className="accordion" id="filterAccordion">
        <div className="accordion-item cat-section">
          <h2 className="accordion-header" id="headingCategory">
            <button
              className="accordion-button cat-accordion-btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseCategory"
              aria-expanded="true"
              aria-controls="collapseCategory"
            >
              Category
            </button>
          </h2>
          <div id="collapseCategory" className="accordion-collapse collapse show" aria-labelledby="headingCategory">
            <div className="accordion-body">
              {filteredCategories.map((category) => (
                <div className="filter-option" key={category.value}>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name={category.value}
                      checked={selectedCategories.includes(category.value)}
                      onChange={() => handleCategoryChange(category.value)}
                    />
                    <span className="checkbox-custom"></span>
                    {category.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="accordion-item cat-section">
          <h2 className="accordion-header" id="headingPriceRange">
            <button
              className="accordion-button cat-accordion-btn collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsePriceRange"
              aria-expanded="false"
              aria-controls="collapsePriceRange"
            >
              Price Range
            </button>
          </h2>
          <div id="collapsePriceRange" className="accordion-collapse collapse cat-section" aria-labelledby="headingPriceRange">
            <div className="accordion-body">
              {priceRanges.map((range) => (
                <div className="filter-option" key={range.name}>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name={range.name}
                      checked={selectedPriceRanges.includes(range.name)}
                      onChange={() => handlePriceRangeChange(range.name)} 
                    />
                    <span className="checkbox-custom"></span>
                    {range.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="accordion-item cat-section custom-disabled">
          <h2 className="accordion-header" id="headingAge">
            <button
              className="accordion-button cat-accordion-btn collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseAge"
              aria-expanded="false"
              aria-controls="collapseAge"
              disabled
            >
              Age
            </button>
          </h2>
          <div id="collapseAge" className="accordion-collapse collapse" aria-labelledby="headingAge">
            <div className="accordion-body">
              {ages.map((age) => (
                <div className="filter-option" key={age.value}>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name={age.value}
                      checked={selectedAges.includes(age.value)}
                      onChange={() => handleAgeChange(age.value)}
                    />
                    <span className="checkbox-custom"></span>
                    {age.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="accordion-item cat-section custom-disabled">
          <h2 className="accordion-header" id="headingWishlist">
            <button
              className="accordion-button cat-accordion-btn collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseWishlist"
              aria-expanded="false"
              aria-controls="collapseWishlist"
              disabled
            >
              Example Wishlists
            </button>
          </h2>
          <div id="collapseWishlist" className="accordion-collapse collapse cat-section" aria-labelledby="headingWishlist">
            <div className="accordion-body">
              {exampleWishlists.map((wishlist) => (
                <div className="filter-option" key={wishlist.name}>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name={wishlist.name}
                      checked={selectedWishlists.includes(wishlist.name)}
                      onChange={() => handleWishlistChange(wishlist.name)}
                    />
                    <span className="checkbox-custom"></span>
                    {wishlist.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="wrapper animsition">
      {loading && (
        <div className="spinner-overlay shop-spinner-bg">
          <div className="spinner-grow custom-shop-spinner" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          <Navbar />
        </header>
        <BackButton />
        <main className="main">
          <article>
            <section>
              <div className="shop-container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="head-section">
                      <div className="category-cover">
                        {coverImage && (
                          <img src={coverImage.src} alt={coverImage.alt} />
                        )}
                      </div>
                      <div className="profile-wrapper">
                        {image && (
                          <img src={image.src} alt={image.alt} className="profile-image" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className="container adjust-product-container mb-5">
                <div className="row">
                  <div className="col-lg-3 col-md-4">
                    <div className="filter-section d-none d-md-block">
                      <h3>Filter by:</h3>
                      {filterCategory}
                    </div>
                    <div className="d-md-none shop-filter">
                      <button className="form-btn" data-bs-toggle="modal" data-bs-target="#filter-modal">View Filter</button>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-8">
                    <div className="product-grid">
                      <div className="row">
                        <div className="top-section d-flex flex-column flex-md-row justify-content-between align-items-center">
                          <div className="filter-part sorting-shop-container">
                            <label htmlFor="sortingselect">Sort By:</label>
                            <div className="select-wrapper sorting-shop">
                              <select
                                name="sorting"
                                id="sortingselect"
                                className="form-control auto-width"
                                value={sortBy}
                                onChange={handleSortChange}
                              >
                                <option value="">Latest</option>
                                <option value="LowToHigh">Price: low to high</option>
                                <option value="HighToLow">Price: high to low</option>
                              </select>
                            </div>
                          </div>
                          <div className="search-part search-registry">
                            <input
                              type="text"
                              placeholder="Search"
                              value={searchQuery}
                              onChange={handleSearchInputChange}
                              onKeyPress={handleKeyPress}
                            />
                            <div className="search-icon-product" onClick={handleSearchIconClick}>
                              <i className="la la-search reversed-icon"></i>
                            </div>
                          </div>
                        </div>

                        {noResults && (
                          <div className="no-info-avilable">
                            <h4 className='mt-5'>Oops! No products found</h4>
                          </div>
                        )}
                        
                        {!noResults && products.map((product, index) => (
                          <div key={product.id} ref={index === products.length - 1 ? lastProductElementRef : null}  className="product-col-container col-lg-3 col-md-6 col-sm-6">
                            <div className="product-item">
                              <img src={product.image} alt={product.name} className="shop-product-image" style={{ height: "200px", objectFit: "contain" }}/>
                              {product.platform_id === 1 && (
                                <img src="/assets/images/partner-logo/amazon-logo.png" alt="Amazon Logo" className="partner-logo-circle" />
                              )}
                              {product.platform_id === 2 && (
                                <img src="/assets/images/partner-logo/r-for-rabbit-logo.png" alt="R-For-Rabbit Logo" className="partner-logo-circle" />
                              )}
                              <p className="product-name clamp-lines">{product.title}</p>
                              <p className="product-price">₹ {product.price}</p>
                              <button className="form-btn shop-btn" onClick={() => handleAddToWishlist(product.id)}>Add to Wishlist</button>
                              <button className="form-btn custom-edit-btn shop-edit-btn shop-btn" onClick={() => handleViewOnline(product)}>View Online</button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </article>
        </main>
        <AddShopGiftModal
          products={products} 
          selectedProductId={selectedProductId}
        />
        <ViewOnlineModal 
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
        />
        <Footer />
      </div>
      
      <div className="modal fade" id="filter-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog common-popup">
          <div className="modal-content">
            <div className="modal-header">
              <p>FILTER BY</p>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="filter-modal-section">
              <div className="modal-body">
                {filterCategory}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;