import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { getRegistryCredentials } from './utils/registryUtils';
import { initializeApi } from "./services/api";
import MainPage from "./pages/Main/MainPage";
import MobileLogin from "./components/auth/MobileLogin";
import Signup from "./components/auth/Signup";
import MobileHomepage from "./components/sections/MobileHomepage";
import FindRegistryPage from "./pages/Main/FindRegistryPage";
import ForgotPasswordPage from "./pages/Main/ForgotPasswordPage";
import AboutUsPage from "./pages/Main/AboutUsPage";
import PrivacyPolicyPage from "./pages/Main/PrivacyPolicyPage";
import TermsAndConditionPage from "./pages/Main/TermsAndConditionPage";
import AdvertiseWithUsPage from "./pages/Main/AdvertiseWithUsPage";
import FeedbackPage from "./pages/Main/FeedbackPage";
import OnboardingProcessPage from "./pages/Dashboard/OnboardingProcessPage";
import SelectRegistryPage from "./pages/Dashboard/SelectRegistryPage";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import AddGiftsPage from "./pages/Dashboard/AddGiftsPage";
import ScriptBlockedPage from "./pages/Main/ScriptBlockedPage";
import ShopPage from "./pages/Main/ShopPage";
import ShowAllPage from "./pages/Main/ShowAllPage";
import ProductPage from "./pages/Main/ProductPage";
import PopularGiftsPage from "./pages/Dashboard/PopularGiftsPage";
import BabyChecklistPage from "./pages/Dashboard/BabyChecklistPage";
import RegistrySettingsPage from "./pages/Dashboard/RegistrySettingsPage";
import NotificationsPage from "./pages/Dashboard/NotifiactionsPage";
import GuestbookPage from "./pages/Dashboard/GuestbookPage";
import VisitorSetupPage from "./pages/Dashboard/VisitorSetupPage";
import ViewAsVisitorPage from "./pages/Dashboard/ViewAsVisitorPage";
import ViewProductPage from "./pages/Dashboard/ViewProductPage";
import PurchaseProcessPage from "./pages/Dashboard/PurchaseProcessPage";
import AddGiftModalPage from "./pages/AddGift/AddGiftModalPage";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import AppUrlListener from "./components/listener/AppUrlListener";
import NotFoundPage from "./pages/Main/NotFoundPage";
import "./App.css";

function App() {
  const { accessToken, fromMobileApp } = getRegistryCredentials();

  return (
    <BrowserRouter>
      <AppUrlListener />
      <RoutesWrapper accessToken={accessToken} fromMobileApp={fromMobileApp} />
    </BrowserRouter>
  );
}

function RoutesWrapper({ accessToken, fromMobileApp }) {
  const navigate = useNavigate();

  useEffect(() => {
    initializeApi(navigate);
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <Routes>
      {fromMobileApp ? (
        <Route path="/" element={accessToken ? <Navigate to="/dashboard" /> : <MainPage />} />
      ) : (
        <Route path="/" element={<MainPage />} />
      )}
      <Route path="/m-login" element={<MobileLogin />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/m-homepage" element={<MobileHomepage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/aboutus" element={<AboutUsPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/terms&conditions" element={<TermsAndConditionPage />} />
      <Route path="/advertise-with-us" element={<AdvertiseWithUsPage />} />
      <Route path="/feedback" element={<FeedbackPage />} />
      <Route path="/search" element={<FindRegistryPage />} />
      <Route path="/add-gifts" element={<AddGiftsPage />} />
      <Route path="/script-blocked" element={<ScriptBlockedPage />} />
      <Route path="/shop" element={<ShopPage />} />
      <Route path="/categories" element={<ShowAllPage />} />
      <Route path="/categories/:category" element={<ProductPage />} />
      <Route path="/baby-checklist" element={<BabyChecklistPage />} />
      <Route path="/giftlist/:publicKey" element={<ViewAsVisitorPage />} />
      <Route path="/view-product" element={<ViewProductPage />} />
      <Route path="/purchase-process" element={<PurchaseProcessPage />} />
      <Route path="/add-gift-modal" element={<AddGiftModalPage />} />
      <Route path="/onboarding-process" element={<ProtectedRoute element={<OnboardingProcessPage />} />} />
      <Route path="/all-registry" element={<ProtectedRoute element={<SelectRegistryPage />} />} />
      <Route path="/dashboard" element={<ProtectedRoute element={<DashboardPage />} />} />
      <Route path="/popular-gifts" element={<ProtectedRoute element={<PopularGiftsPage />} />} />
      <Route path="/visitor-setup" element={<ProtectedRoute element={<VisitorSetupPage />} />} />
      <Route path="/guestbook" element={<ProtectedRoute element={<GuestbookPage />} />} />
      <Route path="/registry-settings" element={<ProtectedRoute element={<RegistrySettingsPage />} />} />
      <Route path="/notifications" element={<ProtectedRoute element={<NotificationsPage />} />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;