/* global $ */
import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import api from '../../../services/api';

const ViewOnlineModal = ({ selectedProduct, setSelectedProduct }) => {
  const [loading, setLoading] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const source = window.location.pathname.split("/")[2];

  useEffect(() => {
    const fetchShopProductDetails = async () => {
      if (!selectedProduct) return;
      setLoading(true);
  
      try {
        const identifier = selectedProduct?.platform_id === 2 ? selectedProduct?.handle : selectedProduct?.id;
        const response = await api.getShopProductDetails(source, identifier, selectedProduct.platform_id);
        if (response && response.images && response.images.length > 1) {
          setProductDetails(response);
        } else {
          setProductDetails(selectedProduct);
        }
        setActiveIndex(0);
      } catch (error) {
        console.error('Error fetching product details:', error);
      } finally {
        setLoading(false);
      }
    };
  
    if (selectedProduct && source) {
      fetchShopProductDetails();
    } else {
      setProductDetails(selectedProduct);
    }
  }, [selectedProduct, source]);

    // const handleAddToWishlist = (productId) => {
  //   $("#view-online-modal").modal("hide");
  //   $("#ShopGift").modal("show");
  // };

  const handleViewOnline = () => {
    if (productDetails && productDetails.url) {
      window.open(productDetails.url, '_blank');
    }
  };

  const handleCloseModal = () => {
    $("#view-online-modal").modal("hide");
    setSelectedProduct(null);
    setProductDetails(null);
  };

  const handleSwipeLeft = () => {
    if (productDetails && productDetails.images && productDetails.images.length > 1) {
      if (activeIndex < productDetails.images.length - 1) {
        setActiveIndex(activeIndex + 1);
      }
    }
  };
  
  const handleSwipeRight = () => {
    if (productDetails && productDetails.images && productDetails.images.length > 1) {
      if (activeIndex > 0) {
        setActiveIndex(activeIndex - 1);
      }
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
    trackMouse: true
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        handleSwipeLeft();
      } else if (event.key === 'ArrowLeft') {
        handleSwipeRight();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line
  }, [activeIndex, productDetails]);

  return (
    <div className="modal fade" id="view-online-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog common-popup shop-popup">
        <div className="modal-content">
          <div className="modal-header">
            <p>PRODUCT DETAILS</p>
            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleCloseModal} aria-label="Close"></button>
          </div>
          <div className="modal-body row">
            <div className="col-lg-12">
              <div className="product-container">
                {loading ? (
                  <div className="spinner-container shop-modal-spinner">
                    <div className="spinner-grow" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : productDetails ? (
                  <>
                    {productDetails.images && productDetails.images.length > 0 ? (
                      <div {...handlers} className="carousel slide">
                        <div className="carousel-indicators">
                          {productDetails.images.map((image, index) => (
                            <button
                              key={index}
                              type="button"
                              className={index === activeIndex ? "active" : ""}
                              aria-current={index === activeIndex ? "true" : "false"}
                              aria-label={`Slide ${index + 1}`}
                              onClick={() => setActiveIndex(index)}
                            ></button>
                          ))}
                        </div>
                        <div className="carousel-inner carousel-product-image">
                          {productDetails.images.map((image, index) => (
                            <div key={index} className={`carousel-item ${index === activeIndex ? "active" : ""}`} style={{ position: "relative" }}>
                              <img src={image} className="d-block w-60 mx-auto modal-shop-img" alt={productDetails.title} style={{ height: "280px", objectFit: "contain" }} />
                              {selectedProduct?.platform_id === 1 && (
                                <div className="partner-logo-modal">
                                  <img src="/assets/images/partner-logo/amazon-logo.png" alt="Amazon Logo" />
                                </div>
                              )}
                              {selectedProduct?.platform_id === 2 && (
                                <div className="partner-logo-modal">
                                  <img src="/assets/images/partner-logo/r-for-rabbit-logo.png" alt="R-For-Rabbit Logo" />
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className="carousel-inner carousel-product-image">
                        <div className="carousel-item active">
                          <img src={productDetails.image} className="d-block w-60 mx-auto" alt={productDetails.title} style={{ height: "280px", objectFit: "contain" }} />
                          {selectedProduct?.platform_id === 1 && (
                            <div className="partner-logo-modal">
                              <img src="/assets/images/partner-logo/amazon-logo.png" alt="Amazon Logo" />
                            </div>
                          )}
                          {selectedProduct?.platform_id === 2 && (
                            <div className="partner-logo-modal">
                              <img src="/assets/images/partner-logo/r-for-rabbit-logo.png" alt="R-For-Rabbit Logo" />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="product-view-container">
                      <h5 className="product-view-name clamp-three-lines">{productDetails?.title}</h5>
                      <p className="product-view-price">₹ {productDetails?.price}</p>
                    </div>
                    <div className="user-detials-btn text-center mb-2">
                      <button type="button" className="shop-modal-btn shop-view-btn common-btn" onClick={handleViewOnline}>View Online</button>
                    </div>
                  </>
                ) : (
                  <div className="no-info-avilable">
                    <h4>No information available for this product</h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );  
};

export default ViewOnlineModal;