import React from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginModal from "../auth/LoginModal";

const MobileHomepage = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/m-login");
  }

  return (
    <>
      <main className="main">
        <article>
          <section className="welcome">
            <div className="mobile-hero-banner">
              <div className="upper-container">
                <div className="spacing-mid"></div>
                <Link to="/" className="mobile-new-logo">
                  <img
                    src="/assets/images/logo.png"
                    alt="logo"
                    loading="lazy"
                  />
                </Link>
                <div className="spacing-mid"></div>
                <p className="store-info">Simplifying Gift-Giving</p>
                <div className="spacing-mid"></div>
              </div>
              <div className="lower-container">
                <button
                  className="mobile-new-btn"
                  onClick={handleLogin}
                >
                  Login
                </button>
                <Link to="/signup" className="mobile-new-btn">
                  Register
                </Link>
                <Link to="/search" className="mt-2 mb-4 mobile-link">
                  FIND A LIST
                </Link>
              </div>
              <div className="mobile-view-footer text-center">
                <p>Made in India &nbsp;<img src="/assets/images/icon/india-flag-icon.svg" alt="India Flag" style={{ width: "18px" }} />&nbsp; with ❤️</p>
              </div>
            </div>
          </section>
        </article>
      </main>
      <LoginModal />
    </>
  );
};

export default MobileHomepage;
