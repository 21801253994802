/* global $ */
import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { showToast } from "../../../utils/toastUtils";
import api from "../../../services/api";
import Cookies from 'js-cookie';

const VerificationModal = ({ fetchShippingData, fetchShippingInfo, myPublicKey }) => {
  const { publicKey } = useParams();
  const key = publicKey || myPublicKey;
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const [visitorName, setVisitorName] = useState("");
  const [visitorPhoneNumber, setVisitorPhoneNumber] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [remainingTime, setRemainingTime] = useState(60);
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);

  useEffect(() => {
    if (otpSent && remainingTime > 0) {
      let timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
  
      return () => clearInterval(timer);
    }
  }, [otpSent, remainingTime]);

  useEffect(() => {
    const validateForm = () => {
      const isPhoneNumberValid = /^\d{10}$/.test(visitorPhoneNumber.trim());
      const isOtpValid = !otpSent || /^\d{6}$/.test(otp.trim());
      const isValid = visitorName.trim() !== "" && isPhoneNumberValid && isOtpValid;

      setIsFormValid(isValid);
    };

    validateForm();
  }, [visitorName, visitorPhoneNumber, otp, otpSent]);

  const handleNameChange = (e) => {
    setVisitorName(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    const digits = value.replace(/\D/g, "");
    setVisitorPhoneNumber(digits);
  };

  const handleOtpChange = (e) => {
    const value = e.target.value;
    const digits = value.replace(/\D/g, "");
    setOtp(digits);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid) {
      try {
        setLoading(true);
        await api.sendOtpToVisitor(key, visitorName, visitorPhoneNumber);
        showToast("OTP sent successfully!");
        setLoading(false);
        setOtpSent(true); 
      } catch (error) {
        console.error("Error sending otp to visitor", error);
      }
    }
  };

  const handleSendAgain = async () => {
    try {
      setRequestLoading(true);
      await api.sendOtpToVisitor(key, visitorName, visitorPhoneNumber);
      showToast("OTP sent again successfully!");
      setRemainingTime(60);
      setOtp("");
    } catch (error) {
      console.error(error.message);
      showToast('Error resending OTP', "error");
    } finally {
      setRequestLoading(false);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await api.validateOtpToVisitor(otp, key, visitorPhoneNumber);
      showToast("Verified successfully!", "success");

      Cookies.set('jsonWebToken', response.jwt, { expires: 30, sameSite: 'None', secure: true });
      localStorage.setItem('jsonWebToken', response.jwt);
      $("#visitor-verification").modal("hide");

      if (location.pathname.includes('/giftlist')) {
        await fetchShippingData();
        $("#shipping-info").modal("show");
      } else if (location.pathname.includes('/view-product')) {
        await fetchShippingInfo();
      }

      resetVisitorDetails();
    } catch (error) {
      if (error.message === "Invalid credentials") {
        showToast("Invalid OTP. Please try again.");
      } else if (error.message === "OTP expired. Please generate OTP again.") {
        showToast("OTP expired. Please request again.");
      } else {
        showToast("Failed to validate OTP. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleVerificationCancel = () => {
    resetVisitorDetails();
  };

  const resetVisitorDetails = () => {
    setOtp("");
    setVisitorName("");
    setVisitorPhoneNumber("");
    setOtpSent(false);
    setIsFormValid(false);
  }

  return (
    <div className="modal fade" id="visitor-verification" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog common-popup">
        <div className="modal-content">
          <div className="modal-header">
            <p>VISITOR VERIFICATION</p>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleVerificationCancel}></button>
          </div>
          <div className="modal-body login-box row">
            <div className="product-form mt-3">
              {!otpSent ? (
                <form noValidate>
                  <div className="user-box">
                    <div className="product-column">
                      <input
                        type="text"
                        name="yourName"
                        value={visitorName}
                        onChange={handleNameChange}
                        onKeyPress={(e) => e.key === 'Enter' && handleSubmit(e)}
                        required
                      />
                      <label>Your Name</label>
                    </div>
                    <div className="product-column no-space">
                      <input
                        type="text"
                        name="phoneNumber"
                        value={visitorPhoneNumber}
                        onChange={handlePhoneNumberChange}
                        onKeyPress={(e) => e.key === 'Enter' && handleSubmit(e)}
                        maxLength={10}
                        required
                      />
                      <label>Your Phone Number</label>
                      <span className="required-info">Please provide your WhatsApp number to receive OTP.</span>
                    </div>
                  </div>
                </form>
              ) : (
                <form noValidate>
                  <div className="user-box otp-box d-flex justify-content-center">
                    <div className="product-column text-center">
                      <input
                        type="text"
                        name="otp"
                        value={otp}
                        onChange={handleOtpChange}
                        onKeyPress={(e) => e.key === 'Enter' && handleVerifyOtp(e)}
                        maxLength={6}
                        required
                      />
                      <label>Enter OTP</label>
                    </div>
                  </div>
                  <div className="text-center">
                    <p className="resend text-muted">{remainingTime > 0 ? `Request again in ${remainingTime}s` : ''}</p>
                    <p className="resend text-muted"> Didn't receive OTP?&nbsp;
                        <Link className={`resend ${remainingTime > 0 || requestLoading  ? 'custom-disabled' : ''}`} onClick={handleSendAgain} disabled={requestLoading}>
                        {requestLoading ? 'Requesting...' : 'Request again'}
                        </Link>
                    </p>
                  </div>
                </form>
              )}
              <button
                type="button"
                className={`btn btn-primary form-btn edit-gift-btn visitor-btn main-btn mx-auto mt-4 mb-3`}
                onClick={!otpSent ? handleSubmit : handleVerifyOtp}
                disabled={!isFormValid || loading}
              >
                {loading ? (otpSent ? "VERIFYING OTP..." : "SENDING OTP...") : (otpSent ? "VERIFY OTP" : "SEND OTP")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerificationModal;