/* global $ */
import React from "react";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../utils/toastUtils";
import api from "../../../services/api";

const RequestPasswordModal = ({ requestInfo }) => {
  const navigate = useNavigate();

  const handleUpdatePasswordRequest = async (status) => {
    try {
      await api.updatePasswordRequest(requestInfo.request_id, status);
      if (status === "Accepted") {
        showToast("Password request approved successfully");
      } else if (status === "Rejected") {
        showToast("Password request rejected successfully");
      }
    } catch (error) {
      showToast("Error occured. Please try again");
      console.error("Error updating password request:", error);
    } finally {
      $("#password-request").modal("hide");
      navigate("/");
    }
  };

  const handleReject = () => {
    handleUpdatePasswordRequest("Rejected");
  };

  const handleApprove = () => {
    handleUpdatePasswordRequest("Accepted");
  };

  return (
    <div className="modal fade" id="password-request" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body row">
            <div className="confirmation-modal text-center mt-4">
              <h5 className="mb-4">Password Request</h5>
              <p><b>{requestInfo?.name}</b> has requested password to view your registry</p>
            </div>
            <div className="user-detials-btn adjust-lower-btn text-center mb-3">
              <button type="button" className="common-modal-btn common-btn mb-2" onClick={handleReject}>Reject</button>
              <button type="button" className="common-modal-btn common-btn active-btn" onClick={handleApprove}>Approve</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestPasswordModal;

