import React from "react";
import { Link } from "react-router-dom";

const ChecklistSection = () => {
  return (
    <main className="main">
      <article>
        <section className="store py-5">
          <div className="icons-bg"></div>

          <div className="container">
            <div className="row">
              <div
                className="col-lg-12"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="store-box baby-check-heading text-center">
                  <h1 className="my-4 heading-med">Your Baby Registry Checklist</h1>
                  <p className="padding-all">
                    Navigate your baby's essentials with our curated checklist. Simply select, search
                    and add items to your registry. Begin your journey to a complete nursery
                  </p>
                  <div className="spacing-mid"></div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <ChecklistItem
                  imgSrc="assets/images/checklist/sleep-time.png"
                  title="Sleepy time"
                />
              </div>

              <div
                className="col-lg-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <ChecklistItem
                  imgSrc="assets/images/checklist/changing-time.png"
                  title="Changing time"
                />
              </div>

              <div
                className="col-lg-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="700"
              >
                <ChecklistItem
                  imgSrc="assets/images/checklist/feeding-time.png"
                  title="Feeding time"
                />
              </div>

              <div
                className="col-lg-12 col-md-12 text-center"
                data-aos="fade-up"
                data-aos-delay="700"
              >
                <div className="spacing-mid"></div>

                <div className="button">
                  <Link to="/baby-checklist" onClick={() => window.scrollTo(0, 0)} className="button-active">
                    Explore Full Checklist
                  </Link>
                </div>

                <div className="spacing-small"></div>
              </div>
            </div>
          </div>
        </section>
      </article>
    </main>
  );
};

const ChecklistItem = ({ imgSrc, title }) => {
  return (
    <div className="checklist-box text-center">
      <div className="checklist-img">
        <img src={imgSrc} alt={title} />
      </div>
      <div className="checklist-text pt-3">
        <h4>{title}</h4>
      </div>
    </div>
  );
};

export default ChecklistSection;
