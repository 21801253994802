import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from "@mui/system";

const InfoIcon = styled('img')({
  width: 17,
  height: 17,
  cursor: "pointer",
});

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#6e3265',
    color: '#ffffff',
    minWidth: 190,
    maxWidth: 190,
    minHeight: 45,
    fontSize: '0.85em',
    border: '1px solid #6e3265',
  },
});

const HtmlTooltips = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    color: '#6e3265',
    minWidth: 190,
    maxWidth: 190,
    minHeight: 45,
    fontSize: '0.85em',
    border: '1px solid #6e3265',
  },
});

export { InfoIcon, HtmlTooltip, HtmlTooltips };
