import React, { useEffect } from 'react';
import Header from "../../components/common/Header";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import { Link } from 'react-router-dom';

const PopularGiftsPage = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          <Navbar />
        </header>
        <main className="main main-dashboard">
          <article>
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="sub-heading text-center">
                      <h3>
                        The Baby Registry Gifts our Members are Loving Now
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="popular-gifts">
                      <article>
                        <Link to="#">
                          <div className="product-img">
                            <img
                              src="https://stmr.blob.core.windows.net/users/ids4170k/4171250/GiftImages/b4c5b907-ce8b-4c28-9021-edf8ece95602_Large.jpg"
                              alt=""
                            />
                          </div>
                          <div className="product-logo my-3">
                            <img
                              src="https://stmr.blob.core.windows.net/cms/Websites/LogoFlat_198917_20210429171659.png"
                              alt=""
                            />
                          </div>
                          <div className="product-info">
                            <p>Graco Pack n Play Portable Playard, Kolb</p>
                          </div>
                          <div className="product-hover">
                            <p>
                              <i className="la la-heart"></i> Add to My Registry
                            </p>
                          </div>
                        </Link>
                      </article>
                      <article>
                        <Link to="#">
                          <div className="product-img">
                            <img
                              src="https://stmr.blob.core.windows.net/users/ids4170k/4171250/GiftImages/b4c5b907-ce8b-4c28-9021-edf8ece95602_Large.jpg"
                              alt=""
                            />
                          </div>
                          <div className="product-logo my-3">
                            <img
                              src="https://stmr.blob.core.windows.net/cms/Websites/LogoFlat_198917_20210429171659.png"
                              alt=""
                            />
                          </div>
                          <div className="product-info">
                            <p>
                              Lorem Ipsum has been the industry's standard dummy
                              text ever since the 1500s, when an unknown printer
                              took a galley of type and scrambled it to make a
                            </p>
                          </div>
                          <div className="product-hover">
                            <p>
                              <i className="la la-heart"></i> Add to My Registry
                            </p>
                          </div>
                        </Link>
                      </article>
                      <article>
                        <Link to="#">
                          <div className="product-img">
                            <img
                              src="https://stmr.blob.core.windows.net/users/ids4170k/4171250/GiftImages/b4c5b907-ce8b-4c28-9021-edf8ece95602_Large.jpg"
                              alt=""
                            />
                          </div>
                          <div className="product-logo my-3">
                            <img
                              src="https://stmr.blob.core.windows.net/cms/Websites/LogoFlat_198917_20210429171659.png"
                              alt=""
                            />
                          </div>
                          <div className="product-info">
                            <p>
                              Lorem Ipsum has been the industry's standard dummy
                              text ever since the 1500s...
                            </p>
                          </div>
                          <div className="product-hover">
                            <p>
                              <i className="la la-heart"></i> Add to My Registry
                            </p>
                          </div>
                        </Link>
                      </article>
                      <article>
                        <Link to="#">
                          <div className="product-img">
                            <img
                              src="https://stmr.blob.core.windows.net/users/ids4170k/4170955/GiftImages/f19b4117-ff1c-4d95-aa6e-b066aebfbc52_Large.jpg"
                              alt=""
                            />
                          </div>
                          <div className="product-logo my-3">
                            <img
                              src="https://stmr.blob.core.windows.net/cms/Websites/LogoFlat_198917_20210429171659.png"
                              alt=""
                            />
                          </div>
                          <div className="product-info">
                            <p>Graco Pack n Play Portable Playard, Kolb</p>
                          </div>
                          <div className="product-hover">
                            <p>
                              <i className="la la-heart"></i> Add to My Registry
                            </p>
                          </div>
                        </Link>
                      </article>
                      <article>
                        <Link to="#">
                          <div className="product-img">
                            <img
                              src="https://stmr.blob.core.windows.net/users/ids4090k/4093905/GiftImages/98215635-cc1d-4b85-ab88-a95424a2d319.jpg"
                              alt=""
                            />
                          </div>
                          <div className="product-logo my-3">
                            <img
                              src="https://stmr.blob.core.windows.net/cms/Websites/LogoFlat_198917_20210429171659.png"
                              alt=""
                            />
                          </div>
                          <div className="product-info">
                            <p>Graco Pack n Play Portable Playard, Kolb</p>
                          </div>
                          <div className="product-hover">
                            <p>
                              <i className="la la-heart"></i> Add to My Registry
                            </p>
                          </div>
                        </Link>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </article>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default PopularGiftsPage;
