/* global $ */
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/common/Header";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import api from "../../services/api";
import { useMediaQuery } from '@mui/material';
import { getRegistryCredentials } from "../../utils/registryUtils";
import { InfoIcon, HtmlTooltip, HtmlTooltips } from '../../utils/tooltipUtils';
import BuyGiftModal from "../../components/dashboard/modal/BuyGiftModal";
import VerificationModal from "../../components/dashboard/modal/VerificationModal";
import BuyMultipleGiftsModal from "../../components/dashboard/modal/BuyMultipleGiftsModal";

const ViewProductPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isPortrait = useMediaQuery('(max-width:575px)');
  const isNavbarVisible = useMediaQuery('(max-width:767px)');
  const searchParams = new URLSearchParams(location.search);
  const itemId = searchParams.get("itemId");
  const regId = searchParams.get("regId");
  const [productData, setProductData] = useState(null);
  const [userAnswers, setUserAnswers] = useState(null);
  const [shippingData, setShippingData] = useState(null);
  const [myPublicKey, setMyPublicKey] = useState(null);
  const [loading, setLoading] = useState(true);
  const { accessToken } = getRegistryCredentials();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const itemId = searchParams.get("itemId");
    const regId = searchParams.get("regId");
  
    if (itemId && regId) {
      sessionStorage.setItem('registryParams', JSON.stringify({
        itemId,
        regId
      }));
    }
  
    if (!itemId || !regId) {
      const storedParams = JSON.parse(sessionStorage.getItem('registryParams'));
      if (storedParams && storedParams.itemId && storedParams.regId) {
        const { itemId: storedItemId, regId: storedRegId } = storedParams;
        navigate(`?itemId=${storedItemId}&regId=${storedRegId}`);
      }
    }
  
  }, [location.search, navigate]);

  const fetchProductData = async () => {
    try {
      if (itemId && regId) {
        const response = await api.getVisitorItem(itemId, regId);
        setProductData(response.data);
        setMyPublicKey(response.data.public_key);
        setUserAnswers(response.data.user_answer);
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const fetchShippingInfo = async () => {
    setLoading(true); 
    const { jsonWebToken } = getRegistryCredentials();
    try {
      if (accessToken) {
        const data = await api.getAddressToVisitor(accessToken, myPublicKey);
        setShippingData(data.shipping_info);
      } else {
        if (jsonWebToken) {
          const data = await api.showAddressToVisitor(jsonWebToken, myPublicKey);
          setShippingData(data.shipping_info);
        } else {
          console.error("Visitor is not verified.");
        }
      }
    } catch (error) {
      console.error("Error fetching shipping data:", error.message);
    } finally {
      setLoading(false);
    }    
  };

  useEffect(() => {
    fetchProductData();
    // eslint-disable-next-line
  }, [itemId, regId]);

  useEffect(() => {
    if (myPublicKey) {
      fetchShippingInfo();
    }
    // eslint-disable-next-line
  }, [accessToken, myPublicKey]);

  const handleVisitorVerification = () => {
    $("#visitor-verification").modal("show");
  }

  const handleAlreadyBought = () => {
    navigate(`/purchase-process?itemId=${itemId}&regId=${regId}&stepNav=2`);
  };

  const handleMultipleGifts = () => {
    $("#BuyMultipleGifts").modal("show");
  }

  return (
    <div className="wrapper animsition">
    {loading ? (
        <div className="custom-loader-container fs-5">
          <div className="spinner-border custom-loader" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          {isNavbarVisible && <Navbar />}
        </header>
        <main className="main">
          <article>
            <section className="py-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="dashboard">
                      <div className="user-dashboard view-product-dashboard">
                        <div className="view-visitor-product">
                          <div className="view-visitor-link">
                            <Link onClick={() => navigate(-1)}>
                              <i className="la la-angle-left"></i>BACK TO&nbsp;
                              {productData?.user_first_name?.toUpperCase()}
                              {userAnswers && userAnswers.find(answer => answer.registry_question_id === 2 && answer.question_answer !== null) && (
                                <> & {userAnswers.find(answer => answer.registry_question_id === 2).question_answer.split(' ')[0].toUpperCase()}</>
                              )}'S GIFT LIST
                            </Link>
                          </div>
                          <div className="product-popup">
                            <div className="modal-body login-box row">
                              <div className="col-lg-6 col-md-6">
                                <div className="product-image">
                                  {productData?.image ? (
                                    <img src={productData?.image} alt="" className="view-product-image d-block mx-auto"/>
                                    ) : (
                                    <img src="/assets/images/no_image_available.png" alt="" className="view-product-image img-fluid d-block mx-auto"/>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6">
                                <div className="product-form">
                                  <div className="view-visitor-product-info clamp-three-lines">
                                    <h5>{productData?.item_name}</h5>
                                  </div>
                                  <div className="product-price">
                                    <h3>₹ {productData?.price}</h3>
                                    <>
                                      <span className="price-note"> *Prices are subject to change. </span>
                                      <HtmlTooltip
                                        title={
                                          <>
                                            <span>Any price change will only be made by the retailer, never by QuickRegistry.in</span>
                                          </>
                                        }
                                        placement={isPortrait ? "bottom" : "right"}
                                        interactive
                                      >
                                        <InfoIcon src="assets/images/icon/info-icon.svg" alt="info-icon" className="info-icon" />
                                      </HtmlTooltip>
                                    </>
                                    <div className="view-visitor-product-details mt-3">
                                        {productData?.item_details?.size && (
                                            <p className="product-detail">Size: {productData.item_details.size}</p>
                                        )}
                                        {productData?.item_details?.color && (
                                            <p className="product-detail">Color: {productData.item_details.color}</p>
                                        )}
                                    </div>
                                    <div className="view-visitor-product-details">
                                        {productData?.item_details?.note && (
                                            <p>Notes: {productData.item_details.note}</p>
                                        )}
                                    </div>
                                  </div>

                                  <div className="user-address mt-3">
                                    <div className="user-address-left">
                                      <i className="la la-shipping-fast"></i>
                                    </div>
                                    <div className="user-address-right">
                                      <p>
                                        Please review the shipping address before proceeding to checkout.
                                      </p>
                                    </div>
                                  </div>

                                  {shippingData ? (
                                  <>
                                    <div className="user-address-shipping-info">
                                      <p className="user-address-details">
                                        {shippingData.first_name}&nbsp;
                                        {shippingData.last_name}
                                        <br />
                                        {shippingData.address_1}
                                        {shippingData.address_2 && `, ${shippingData.address_2}`}
                                        <br />
                                        {shippingData.city},&nbsp;
                                        {shippingData.state},&nbsp;
                                        {shippingData.zip_code}
                                        <br />
                                        {shippingData.country}
                                      </p>
                                      <div className="mt-3 mb-4">
                                      {shippingData.instructions && (
                                        <>
                                          <span className="change-name-color">See shipping instructions: </span>
                                          <HtmlTooltips
                                            title={
                                              <>
                                                <span>{shippingData.instructions}</span>
                                              </>
                                            }
                                            placement={isPortrait ? "bottom" : "right"}
                                            interactive
                                          >
                                            <InfoIcon src="assets/images/icon/info-icon.svg" alt="info-icon" className="info-icon" />
                                          </HtmlTooltips>
                                        </>
                                      )}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="prod-verify-msg mt-5 mb-4">
                                      <p>Please verify yourself to view the shipping address.
                                      <br />
                                      <Link className="change-name-color" onClick={handleVisitorVerification}>CLICK HERE </Link> 
                                      to verify now.</p>
                                    </div>
                                  )}

                                  <button
                                    type="button"
                                    className="form-btn view-product-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#buy-product"
                                  >
                                  {productData && productData.platform_name ? (
                                    `BUY THIS GIFT AT ${productData.platform_name?.toUpperCase()}`
                                  ) : (
                                    'BUY THIS GIFT'
                                  )}

                                  </button>
                                  <div className="button-already button-bought primary-button">
                                      <button type="button" onClick={handleAlreadyBought} className="text-decoration-none">
                                          I bought this gift already
                                      </button>
                                      <span className="pipe change-color"> &nbsp; | &nbsp; </span>
                                      <button type="button" onClick={handleMultipleGifts} className="text-decoration-none">
                                          Buy multiple gifts<span><i className="la la-angle-right"></i></span>
                                      </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </article>
         </main>
        <BuyGiftModal productData={productData} />
        <VerificationModal 
          fetchShippingInfo={fetchShippingInfo} 
          myPublicKey={myPublicKey}
        />
        <BuyMultipleGiftsModal productData={productData} />
        <Footer />
      </div>
    )}
    </div>
  );
};

export default ViewProductPage;
