import React, { useState, useEffect } from "react";
import Header from "../../components/common/Header";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import { getRegistryCredentials } from '../../utils/registryUtils';
import ProfileTab from "../../components/dashboard/registrySettings/ProfileTab";
import LifafaTab from "../../components/dashboard/registrySettings/LifafaTab";
import NotificationsTab from "../../components/dashboard/registrySettings/NotificationsTab";
import api from "../../services/api";

const RegistrySettingsPage = () => {
  const [loading, setLoading] = useState(true);
  const [settingDetails, setSettingDetails] = useState(null);
  const { accessToken, registryId, registryTypeName } = getRegistryCredentials();

  useEffect(() => {
    fetchSettingDetails();
    // eslint-disable-next-line
  }, [registryId]);

  const fetchSettingDetails = async () => {
    try {
      const details = await api.getSettingDetails(accessToken, registryId);
      setSettingDetails(details);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching setting details:", error);
      setLoading(true);
    }
  };

  const registryName = settingDetails?.registry_name?.toUpperCase() || '';

  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          <Navbar />
        </header>
        {loading ? (
            <div className="custom-loader-container main-loader fs-5">
              <div className="spinner-border custom-loader" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
        ) : (
        <main className="main main-dashboard">
          <article>
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="dashboard">
                      <div className="user-settings">
                        <div className="settings-info text-center mb-4">
                          <div className="user-name mb-3">
                            <h3>My Settings</h3>
                          </div>
                          <div className="user-stats">
                            <p>
                              MY REGISTRY NAME IS: <span className="change-color">{registryName}</span>
                              <br />
                              THIS INFORMATION APPLIES ONLY TO YOUR {registryTypeName ? registryTypeName.toUpperCase() : ''}
                            </p>
                          </div>
                        </div>
                        <div className="accordion" id="accordion1">
                        <ProfileTab 
                          settingDetails={settingDetails} 
                          fetchSettingDetails={fetchSettingDetails}
                        />
                        <LifafaTab 
                          settingDetails={settingDetails} 
                          fetchSettingDetails={fetchSettingDetails}
                        />
                        <NotificationsTab />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </article>
        </main>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default RegistrySettingsPage;