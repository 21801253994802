export const questionsByRegistryType = {
  1: {
    name: "My Baby Registry",
    description: "We are having a new addition to our family!",
    sequence1: {
      question: "Are you the... ?",
      options: ["Mom", "Dad", "Partner"],
    },
    sequence2: {
      question: "Do you have a partner on this adventure?",
      options: ["Mom", "Dad", "Partner"],
    },
    sequence3: {
      question: "Congrats! When is this baby arriving?",
      inputLabel: "Arrival Date",
    },
  },
  2: {
    name: "My Wedding Registry",
    description: "We are excited to start this new chapter!",
    sequence1: {
      question: "Are you the... ?",
      options: ["Bride", "Groom", "Partner"],
    },
    sequence2: {
      question: "And who's your lucky partner?",
      options: ["Bride", "Groom", "Partner"],
    },
    sequence3: {
      question: "Have You two set a date?",
      inputLabel: "Event Date",
    },
  },
};
