import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getRegistryCredentials } from '../../utils/registryUtils';

const BackButton = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const { fromiOSDevice } = getRegistryCredentials();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!isMobile) return null;

  return (
    <div className={`back-btn-text back-with-nav ${fromiOSDevice && 'back-nav-area'}`}>
      <p>
        <Link className="back-button back-btn-nav" onClick={() => navigate(-1)}>
          <i className="la la-angle-left"></i>
        </Link>
      </p>
    </div>
  );
};

export default BackButton;
