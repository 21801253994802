import React, { useState, useEffect, useCallback } from "react";
import Header from "../../components/common/Header";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import { showToast } from "../../utils/toastUtils";
import { getRegistryCredentials } from "../../utils/registryUtils";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import api from "../../services/api";
import debounce from 'lodash/debounce';

const BabyChecklistPage = () => {
  const totalItems = 112;
  const [totalChecked, setTotalChecked] = useState(0);
  const [itemsPercentage, setItemPercentage] = useState(0);
  const [checklistData, setChecklistData] = useState([]);
  const [pendingChanges, setPendingChanges] = useState([]);
  const { accessToken, registryId } = getRegistryCredentials();

  const fetchChecklistItems = async () => {
    try {
      const response = await api.getChecklistDetails(accessToken, registryId);
      const checklistData = response.data;
      updateChecklistItems(checklistData);
      setChecklistData(checklistData);
      setTotalChecked(response.total_checked);
      setItemPercentage((response.total_checked / totalItems) * 100);
    } catch (error) {
      showToast("Failed to fetch checklists", "error");
    }
  };

  useEffect(() => {
    if (accessToken) {
      fetchChecklistItems();
    }
    // eslint-disable-next-line
  }, [accessToken, registryId]);

  const updateChecklistItems = (checklistData) => {
    checklistData.forEach(item => {
      const checkbox = document.getElementById(`item${item.id}`);
      if (checkbox) {
        checkbox.checked = item.checked;
      }
    });
  };

  const updateChecklist = async (formattedData) => {
    try {
      await api.updateChecklist(accessToken, registryId, formattedData);
      fetchChecklistItems();
      setPendingChanges([]);
    } catch (error) {
      showToast("Failed to update checklists", "error");
    }
  };

  // eslint-disable-next-line
  const debouncedUpdateChecklist = useCallback(debounce((pendingChanges) => {
    const formattedData = {
      checklist: pendingChanges
    };
    updateChecklist(formattedData);
  }, 2000), []);

  const handleCheckboxChange = (event) => {
    if (!accessToken) {
      showToast('Please log in to save your progress');
      return;
    }

    const id = parseInt(event.target.id.replace('item', ''));
    const updatedItems = [...checklistData];
    const currentItemIndex = updatedItems.findIndex(item => item.id === id);

    if (currentItemIndex !== -1) {
      updatedItems[currentItemIndex].checked = !updatedItems[currentItemIndex].checked;
    } else {
      updatedItems.push({
        id: id,
        checked: true
      });
    }

    setChecklistData(updatedItems);

    const changedItem = updatedItems.find(item => item.id === id);
    const newPendingChanges = [...pendingChanges];
    const existingChangeIndex = newPendingChanges.findIndex(item => item.id === id);

    if (existingChangeIndex !== -1) {
      newPendingChanges[existingChangeIndex].checked = changedItem.checked;
    } else {
      newPendingChanges.push({
        id: changedItem.id,
        checked: changedItem.checked
      });
    }

    setPendingChanges(newPendingChanges);
    debouncedUpdateChecklist(newPendingChanges);
  };
  
  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          <Navbar />
        </header>
        <main className="main main-dashboard">
          <article>
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="baby-main-header">
                      <div className="checklist-heading text-center">
                        <h3>Baby Checklist</h3>
                        <p>Find and track everything you need for baby</p>
                      </div>
                      <div className="checklist-subinfo">
                        <div className="left-info">
                          <div className="progress-wrapper">
                            <CircularProgressbar 
                                value={itemsPercentage} 
                                text={`${itemsPercentage.toFixed(0)}%`}
                                styles={{
                                  root: { width: '70px', height: '70px' },
                                  path: { stroke: '#6e3265' },
                                  text: {  fill: '#6e3265', fontSize: '24px' },
                                }}
                              />
                          </div>
                          <div className="progress-details">
                            <p>{totalChecked} out of {totalItems} items are <br /> marked complete ({itemsPercentage.toFixed(0)}%).</p>
                          </div>
                        </div>
                        <div className="checklist-divider"></div>
                        <div className="right-info">
                          <ul>
                            <li>Your checklist automatically checks off items as you add them to your baby wish list.</li>
                            <li>You can also manually check off items you already have or don't need.</li>
                          </ul>
                        </div>                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="py-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="accordion" id="accordion1">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Feeding
                          </button>
                        </h2>
                        
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item1" className="d-block">
                                    <div className="list">
                                      <input id="item1" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/feeding/bottle_feeding.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Bottle Feeding</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item2" className="d-block">
                                    <div className="list">
                                      <input id="item2" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/feeding/bottle_nipples.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Bottle Nipples</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item3" className="d-block">
                                    <div className="list">
                                      <input id="item3" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/feeding/bottle_cleaner.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Bottle Cleanings & Accessories</p>
                                    </div>
                                  </label>
                                </div>
                              </div>
                              
                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item4" className="d-block">
                                    <div className="list">
                                      <input id="item4" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/feeding/sterilizer.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Warmers & Sterilizers</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item5" className="d-block">
                                    <div className="list">
                                      <input id="item5" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/feeding/bibs.png" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Bibs</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item6" className="d-block">
                                    <div className="list">
                                      <input id="item6" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/feeding/high_chairs.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>High Chairs & Booster Seats</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item7" className="d-block">
                                    <div className="list">
                                      <input id="item7" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/feeding/infact_nutrition.png" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Infant Nutrition</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item8" className="d-block">
                                    <div className="list">
                                      <input id="item8" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/feeding/food_storage.png" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Food & Milk Storage</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item9" className="d-block">
                                    <div className="list">
                                      <input id="item9" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/feeding/tableware.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Tableware</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item10" className="d-block">
                                    <div className="list">
                                      <input id="item10" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/feeding/bottle_covers.png" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Bottle Covers</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item11" className="d-block">
                                    <div className="list">
                                      <input id="item11" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/feeding/muslin_squares.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Muslin Squares</p>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                            Maternity & Nursing
                          </button>
                        </h2>
                        
                        <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item12" className="d-block">
                                    <div className="list">
                                      <input id="item12" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/maternity/breast_pumps.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Breast Pumps</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item13" className="d-block">
                                    <div className="list">
                                      <input id="item13" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/maternity/breastfeeding_pillows.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Breastfeeding Pillows</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item14" className="d-block">
                                    <div className="list">
                                      <input id="item14" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/maternity/nursing_cover.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Nursing Covers</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item15" className="d-block">
                                    <div className="list">
                                      <input id="item15" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/maternity/maternity_tops.png" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Maternity Tops</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item16" className="d-block">
                                    <div className="list">
                                      <input id="item16" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/maternity/nipple_cream.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Nipple Cream</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item17" className="d-block">
                                    <div className="list">
                                      <input id="item17" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/maternity/breast_pads.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Breast Pads</p>
                                    </div>
                                  </label>
                                </div>
                              </div>
                              
                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item18" className="d-block">
                                    <div className="list">
                                      <input id="item18" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/maternity/nursing_lingerie.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Nursing Lingerie</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item19" className="d-block">
                                    <div className="list">
                                      <input id="item19" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/maternity/nursing_dress.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Nursing Dress</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item20" className="d-block">
                                    <div className="list">
                                      <input id="item20" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/maternity/maternity_lingerie.png" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Maternity Lingerie</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item21" className="d-block">
                                    <div className="list">
                                      <input id="item21" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/maternity/maternity_dress.png" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Maternity Dress</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item22" className="d-block">
                                    <div className="list">
                                      <input id="item22" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/maternity/personal_care.png" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Maternity Personal Care</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item23" className="d-block">
                                    <div className="list">
                                      <input id="item23" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/maternity/pregency_pillow.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Pregnancy Pillows</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item24" className="d-block">
                                    <div className="list">
                                      <input id="item24" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/maternity/belly_support.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Belly Support Belts</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item25" className="d-block">
                                    <div className="list">
                                      <input id="item25" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/maternity/stockings.jpg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Stocks & Stockings</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item26" className="d-block">
                                    <div className="list">
                                      <input id="item26" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/maternity/lactation_boosters.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Nutrition & Lactation Boosters</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item27" className="d-block">
                                    <div className="list">
                                      <input id="item27" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/maternity/health_supplement.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Vitamins & Health Supplement</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item28" className="d-block">
                                    <div className="list">
                                      <input id="item28" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/maternity/anti_stretch_cream.jpg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Anti Stretch Cream & Lotions</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item29" className="d-block">
                                    <div className="list">
                                      <input id="item29" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/maternity/breast_serums.jpg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Breast Care Oil & Serums</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item30" className="d-block">
                                    <div className="list">
                                      <input id="item30" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/maternity/maternity_pads.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Maternity Pads & Pants</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item31" className="d-block">
                                    <div className="list">
                                      <input id="item31" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/maternity/intimate_wash.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Intimate Wash</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item32" className="d-block">
                                    <div className="list">
                                      <input id="item32" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/maternity/intimate_wipes.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Intimate Wipes</p>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                            Diapering
                          </button>
                        </h2>
                        
                        <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingThree" >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item33" className="d-block">
                                    <div className="list">
                                      <input id="item33" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/diapering/diaper_pants.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Diaper Pants & Taped Diapers</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item34" className="d-block">
                                    <div className="list">
                                      <input id="item34" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/diapering/baby_wipes.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Baby Wipes</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item35" className="d-block">
                                    <div className="list">
                                      <input id="item35" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/diapering/diaper_pail.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Diaper Pail & Refill Bags</p>
                                    </div>
                                  </label>
                                </div>
                              </div>
                              
                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item36" className="d-block">
                                    <div className="list">
                                      <input id="item36" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/diapering/rash_cream.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Diaper Rash Creams</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item37" className="d-block">
                                    <div className="list">
                                      <input id="item37" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/diapering/diaper_bags.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Diaper Bags</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item38" className="d-block">
                                    <div className="list">
                                      <input id="item38" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/diapering/diaper_covers.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Diaper Covers</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item39" className="d-block">
                                    <div className="list">
                                      <input id="item39" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/diapering/cloth_diapers.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Cloth Diapers</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item40" className="d-block">
                                    <div className="list">
                                      <input id="item40" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/diapering/diaper_bins.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Diaper Bins & Disposable Bags</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item41" className="d-block">
                                    <div className="list">
                                      <input id="item41" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/diapering/potty_seats.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Potty Chair and Seats</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item42" className="d-block">
                                    <div className="list">
                                      <input id="item42" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/diapering/cloth_nappies.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Cloth Nappies</p>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                            Nursery Bedding and Essentials
                          </button>
                        </h2>
                        
                        <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item43" className="d-block">
                                    <div className="list">
                                      <input id="item43" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/bedding/toddler_beds.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Infant & Toddler Beds</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item44" className="d-block">
                                    <div className="list">
                                      <input id="item44" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/bedding/mattress.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Mattresses & Protectors</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item45" className="d-block">
                                    <div className="list">
                                      <input id="item45" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/bedding/sleep_bags.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Sleeping Bags & Togs</p>
                                    </div>
                                  </label>
                                </div>
                              </div>
                              
                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item46" className="d-block">
                                    <div className="list">
                                      <input id="item46" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/bedding/sheets.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Sheets & Protectors</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item47" className="d-block">
                                    <div className="list">
                                      <input id="item47" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/bedding/blankets.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Blankets & Swaddlers</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item48" className="d-block">
                                    <div className="list">
                                      <input id="item48" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/bedding/accessories.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Bedding Accessories</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item49" className="d-block">
                                    <div className="list">
                                      <input id="item49" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/bedding/storage.jpg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Storage & More (Hangers)</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item50" className="d-block">
                                    <div className="list">
                                      <input id="item50" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/bedding/decor.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Baby Room Decor</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item51" className="d-block">
                                    <div className="list">
                                      <input id="item51" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/bedding/pillow.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Pillows & Pillow Covers</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item52" className="d-block">
                                    <div className="list">
                                      <input id="item52" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/bedding/essentials.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Baby Room Essentials</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item53" className="d-block">
                                    <div className="list">
                                      <input id="item53" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/bedding/bedding_sets.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Kids Bedding Sets</p>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFive">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                            Bath & Skin Care
                          </button>
                        </h2>
                        
                        <div id="collapseFive" className="accordion-collapse collapse show" aria-labelledby="headingFive" >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item54" className="d-block">
                                    <div className="list">
                                      <input id="item54" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/bath/bath_tubes.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Bath Tubs & Seats</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item55" className="d-block">
                                    <div className="list">
                                      <input id="item55" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/bath/towels.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Bath Towels & Wash Cloths</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item56" className="d-block">
                                    <div className="list">
                                      <input id="item56" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/bath/toys.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Bath Toys</p>
                                    </div>
                                  </label>
                                </div>
                              </div>
                              
                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item57" className="d-block">
                                    <div className="list">
                                      <input id="item57" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/bath/shampoo.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Baby Shampoos</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item58" className="d-block">
                                    <div className="list">
                                      <input id="item58" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/bath/soap.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Baby Soaps</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item59" className="d-block">
                                    <div className="list">
                                      <input id="item59" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/bath/body_wash.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Body Washes</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item60" className="d-block">
                                    <div className="list">
                                      <input id="item60" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/bath/lotion.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Lotions</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item61" className="d-block">
                                    <div className="list">
                                      <input id="item61" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/bath/oil.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Baby Oils</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item62" className="d-block">
                                    <div className="list">
                                      <input id="item62" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/bath/powder.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Powders</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item63" className="d-block">
                                    <div className="list">
                                      <input id="item63" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/bath/cream.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Babycream & Ointments</p>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-2-5 mb-4">
                                <div className="main-card">
                                  <label htmlFor="item68" className="d-block">
                                    <div className="list">
                                      <input id="item68" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                    </div>
                                    <div className="card-body-details text-center">
                                      <img src="/assets/images/baby-checklist/care/laundry_care.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                      <p>Baby Laundry Care</p>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingSix">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                              Baby Care
                            </button>
                          </h2>
                          
                          <div id="collapseSix" className="accordion-collapse collapse show" aria-labelledby="headingSix" >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item65" className="d-block">
                                      <div className="list">
                                        <input id="item65" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/care/care_product.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Baby Ear & Nose Care</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item66" className="d-block">
                                      <div className="list">
                                        <input id="item66" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/care/nail_care.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Nail Care</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item69" className="d-block">
                                      <div className="list">
                                        <input id="item69" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/care/saftey.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Baby Saftey</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item70" className="d-block">
                                      <div className="list">
                                        <input id="item70" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/care/combs.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Brushs & Combs</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item71" className="d-block">
                                      <div className="list">
                                        <input id="item71" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/care/hair_oil.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Hair Oil</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item72" className="d-block">
                                      <div className="list">
                                        <input id="item72" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/care/repellent.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Mosquito Repellent & Care</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item77" className="d-block">
                                      <div className="list">
                                        <input id="item77" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/health/proofing.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Baby Helmet & Head Supporters</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingSeven">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                              Health
                            </button>
                          </h2>
                          
                          <div id="collapseSeven" className="accordion-collapse collapse show" aria-labelledby="headingSeven" >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item73" className="d-block">
                                      <div className="list">
                                        <input id="item73" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/health/air_purifier.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Air Purifiers</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item74" className="d-block">
                                      <div className="list">
                                        <input id="item74" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/health/handwash.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Handwash</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item75" className="d-block">
                                      <div className="list">
                                        <input id="item75" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/health/sanitizer.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Sanitizers</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                                
                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item76" className="d-block">
                                      <div className="list">
                                        <input id="item76" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/health/disinfect.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Disinfectants</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item78" className="d-block">
                                      <div className="list">
                                        <input id="item78" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/health/medical_care.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Medical Care</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item79" className="d-block">
                                      <div className="list">
                                        <input id="item79" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/health/thermometer.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Thermometer</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item80" className="d-block">
                                      <div className="list">
                                        <input id="item80" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/health/toy_cleaner.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Toy Cleaner</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item81" className="d-block">
                                      <div className="list">
                                        <input id="item81" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/health/fruit_cleaner.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Fruit/Vegetable Cleaner</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item82" className="d-block">
                                      <div className="list">
                                        <input id="item82" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/health/floor_cleaner.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Floor Cleaner</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingEight">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                              Activity & Gear
                            </button>
                          </h2>
                          
                          <div id="collapseEight" className="accordion-collapse collapse show" aria-labelledby="headingEight" >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item83" className="d-block">
                                      <div className="list">
                                        <input id="item83" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/activity/strollers.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Strollers & Prams</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item84" className="d-block">
                                      <div className="list">
                                        <input id="item84" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/activity/carry_cots.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Carry Cots</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item85" className="d-block">
                                      <div className="list">
                                        <input id="item85" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/activity/car_seats.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Car Seats</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                                
                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item86" className="d-block">
                                      <div className="list">
                                        <input id="item86" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/activity/playmats.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Playmats & Floor Gyms</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item87" className="d-block">
                                      <div className="list">
                                        <input id="item87" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/activity/swings.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Swings, Bouncers & Rockers</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item88" className="d-block">
                                      <div className="list">
                                        <input id="item88" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/activity/playards.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Playards</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item89" className="d-block">
                                      <div className="list">
                                        <input id="item89" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/activity/carriers.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Baby Carriers</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item90" className="d-block">
                                      <div className="list">
                                        <input id="item90" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/activity/scooters.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Ride Ons & Scooters</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item91" className="d-block">
                                      <div className="list">
                                        <input id="item91" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/activity/tricycle.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Tricycles & Bikes</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item92" className="d-block">
                                      <div className="list">
                                        <input id="item92" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/activity/travel.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Travel Accessories</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingNine">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine">
                              Toys & Books
                            </button>
                          </h2>
                          
                          <div id="collapseNine" className="accordion-collapse collapse show" aria-labelledby="headingNine" >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item93" className="d-block">
                                      <div className="list">
                                        <input id="item93" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/toys/pacifiers.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Pacifiers & Teethers</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item94" className="d-block">
                                      <div className="list">
                                        <input id="item94" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/toys/rattles.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Rattles & Plush Rings</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item95" className="d-block">
                                      <div className="list">
                                        <input id="item95" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/toys/sorting.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Sorting & Stacking Toys</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                                
                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item96" className="d-block">
                                      <div className="list">
                                        <input id="item96" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/toys/sound_toys.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Sound Toys</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item97" className="d-block">
                                      <div className="list">
                                        <input id="item97" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/toys/baby_books.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Books for Baby - 2 Years</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item98" className="d-block">
                                      <div className="list">
                                        <input id="item98" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/toys/gym_mat.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Activity/Gym Mat</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item99" className="d-block">
                                      <div className="list">
                                        <input id="item99" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/toys/soft_toy.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Soft Toys</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item100" className="d-block">
                                      <div className="list">
                                        <input id="item100" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/toys/board_games.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Board Games</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item101" className="d-block">
                                      <div className="list">
                                        <input id="item101" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/toys/home_playing.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Home Playing Activities</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item102" className="d-block">
                                      <div className="list">
                                        <input id="item102" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/toys/puzzles.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Puzzles</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item103" className="d-block">
                                      <div className="list">
                                        <input id="item103" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/toys/cars.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Cars & Bikes</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTen">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="true" aria-controls="collapseTen">
                              Baby Clothing
                            </button>
                          </h2>
                          
                          <div id="collapseTen" className="accordion-collapse collapse show" aria-labelledby="headingTen" >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item104" className="d-block">
                                      <div className="list">
                                        <input id="item104" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/clothing/bodysuits.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Bodysuits</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item105" className="d-block">
                                      <div className="list">
                                        <input id="item105" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/clothing/leggings.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Leggings</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item106" className="d-block">
                                      <div className="list">
                                        <input id="item106" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/clothing/sleepwear.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Sleepwear</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                                
                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item107" className="d-block">
                                      <div className="list">
                                        <input id="item107" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/clothing/cloth_sets.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Clothing Sets</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item108" className="d-block">
                                      <div className="list">
                                        <input id="item108" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/clothing/socks.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Socks</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item109" className="d-block">
                                      <div className="list">
                                        <input id="item109" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/clothing/hats.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Hats & Caps</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item110" className="d-block">
                                      <div className="list">
                                        <input id="item110" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/clothing/gloves.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Gloves & Mittens</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item111" className="d-block">
                                      <div className="list">
                                        <input id="item111" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/clothing/ethnic.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Ethnicwear</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-2-5 mb-4">
                                  <div className="main-card">
                                    <label htmlFor="item112" className="d-block">
                                      <div className="list">
                                        <input id="item112" type="checkbox" className="checklist-input top-0 start-0" onChange={handleCheckboxChange}/>
                                      </div>
                                      <div className="card-body-details text-center">
                                        <img src="/assets/images/baby-checklist/clothing/party.jpeg" className="img-fluid mx-auto d-block" alt="" />
                                        <p>Partywear</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </article>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default BabyChecklistPage;