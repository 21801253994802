import React from "react";
import { Link } from "react-router-dom";

const BabyRegistrySection = () => {
  return (
    <main className="main">
      <article>
        <section className="start-reg" data-aos="fade-up" data-aos-delay="100">
          <div className="hero-banner">
            <div className="container">
              <div className="row">
                <div
                  className="start-reg-box"
                  data-aos="fade-down"
                  data-aos-delay="100"
                >
                  <img src="assets/images/baby-reg-1.png" alt="" />
                </div>

                <div className="col-md-12 text-center">
                  <div className="store-box">
                    <div className="spacing"></div>
                    <div className="spacing-small"></div>

                    <h1 className="my-4 heading-med">
                      Ready to start your baby registry?
                    </h1>
                    <p className="mb-5">
                      Want to know more about creating a Universal Gift
                      Registry? Start here.
                    </p>

                    <div className="button">
                      <Link
                        to="/signup"
                        className="button-active"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        SignUp Now
                      </Link>
                    </div>

                    <div className="spacing"></div>
                  </div>
                </div>

                <div
                  className="start-reg-box right-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <img src="assets/images/baby-reg-2.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
    </main>
  );
};

export default BabyRegistrySection;
